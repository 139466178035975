//
// Cards
//
.card {
  // border: 1px solid #E1E8F0;
}
.white_header {
  background: white !important;
}
@import "cards/card";
@import "cards/card-profile";
@import "cards/card-animations";
@import "cards/card-stats";
