.slidecontainer {
    width: 100%; /* Width of the outside container */
  }
  
  /* The slider itself */
  .slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 12px; /* Specified height */
    border-radius: 5px;
    background: linear-gradient(to right, #0A8080 0%, #0A8080 100%); /* Initial background color (progress) */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
  }
  
  /* Mouse-over effects */
  .slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    /* background: url('../../assets/img/icons/progress_circle.svg'); Progress color */
    border-radius: 50%;
    cursor: pointer; /* Cursor on hover */
  }
  
  .slider::-moz-range-thumb {
    width: 24px; /* Set a specific slider handle width */
    height: 24px; /* Slider handle height */
    background: #0A8080; /* Progress color */
    background: url('../../assets/img/icons/progress_circle.svg');
    cursor: pointer; /* Cursor on hover */
  }
  