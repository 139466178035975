.no_shadow {
  box-shadow: none !important;
}
.ft_white:hover {
  color: white !important;
}
.request_button_div {
  display: flex;
  justify-content: flex-end;
}
.details_parent_card_holder {
  display: flex;
  justify-content: space-between;
}
.request_left_card {
  width: 70%;
  padding: 25px;
  p {
    margin: 0px;
  }
}
.request_right_card {
  width: 29%;
  padding: 25px;
  h2 {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 15px;
    color: #374957;
  }
  .customer_req_detail_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left_customer_req_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin-right: 10px;
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 50px !important;
    }
  }
  .right_customer_req_div {
    width: 70%;
    h3 {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .detail_with_icon {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 16px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      p {
        line-height: 1.3 !important;
        overflow: hidden;
      }
    }
  }
  .host_customer_detail {
    font-size: 13px;
    margin: 0px;
  }
}
.individual_details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 28px;
}
.header_and_data {
  display: flex;
}
.content_div_request {
  width: 60%;
}
.header_and_data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .header {
    width: 105px;
    font-weight: 500;
    font-size: 14px;
    color: #a3a3a3;
  }
  .header_data {
    font-weight: 500;
    font-size: 14px;
    color: #374957;
    width: 350px;
  }
}
.colon_seperator {
  width: 20px;
}
.request_small_width_div {
  width: 35% !important;
  .header_data {
    width: 175px !important;
  }
}
.request_card_detail_divider {
  border-top: 1px solid rgba(225, 225, 225, 0.4);
  margin: 30px 0px 20px 0px;
}
.request_image_map_div {
  display: flex;
  justify-content: space-between;
  .request_images {
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;
  }
  .photo_header {
    color: #374957;
    font-size: 15px !important;
    font-weight: 600;
    width: 25%;
    padding-left: 30px;
  }
  .request_image_container {
    display: flex;
    width: 76%;
  }
  .request_detail_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .request_image {
    width: 100%;
    height: 110px;
    margin-right: 6px;
    object-fit: cover;
    border-radius: 5px;
    position: relative;
  }
  .request_map {
    width: 29%;
    border-radius: 5px;
    height: 110px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
.timeline_card {
  padding: 25px;
  margin-top: 10px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  h2 {
    font-weight: 600;
    font-size: 15px;
  }
  .timeline_div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 340px 25px;
  }
  .timeline_common_line {
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
    position: relative;
  }
}
.status_content_box {
  border: 1px dashed #e3e3e3;
}
.req_individual_design {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  width: 16.6%;
  top: -54px;
  height: 500px;
}

.circle_joining_line {
  height: 180px;
  border-left: 1px dashed #e3e3e3;
}
.ver_circle_joining_line {
  height: 140px;
  border-left: 3px solid #e3e3e3;
}

.success_line {
  height: 180px;
  border-left: 1px dashed #2e1a47 !important;
}
.ver_success_line {
  height: 140px;
  border-left: 3px solid #2e1a47 !important;
}
.progress_line {
  height: 180px;
  border-left: 1px dashed #f7b84a !important;
}
.ver_progress_line {
  height: 140px;
  border-left: 3px solid #f7b84a !important;
}
.completed_box_color {
  border: 1px dashed #2e1a47 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
}
.ver_completed_box_color {
  transform: translateY(-13%) !important;
  border: 1px solid #2e1a47 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  width: 320px;
  position: relative;
}
.arrow {
  position: absolute;
  content: " ";
  height: 11px;
  width: 11px;
  left: -5.8px;
  top: 8px;
  border-top: 1px solid #fff;

  border-right: 1px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #fff;
  z-index: -1;
}
.completed_arrow_box {
  border-left: 1px solid #2e1a47;
  border-bottom: 1px solid #2e1a47;
}
.pending_arrow_box {
  border-left: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
.in_progress_arrow_box {
  border-left: 1px solid #f7b84a;
  border-bottom: 1px solid #f7b84a;
}
.status_content_box {
  border: 1px dashed #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
}
.ver_status_content_box {
  transform: translateY(-13%) !important;
  border: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  width: 320px;
  color: #e3e3e3;
}
.progress_box_color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
  border: 1px dashed #f7b84a;
}
.ver_progress_box_color {
  transform: translateY(-13%) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  text-align: center;
  border: 1px solid #f7b84a;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
  width: 320px;
}
.request_complete_stages_div {
  padding: 30px 20px 20px 25px;

  h2 {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
  }
  .buttons_div,
  .stage_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .button-wrapping {
    margin-bottom: 24px;
  }
}
.req_side_bar {
  width: 22vw !important;
}
.request_amount_input {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 14px 17px;
  font-weight: 400;
  font-size: 13px;
  color: #ababab;
  width: 100%;
}
.view_on_map {
  position: absolute;
  left: 2%;
  top: 13%;
  background: white;
  outline: none;
  border: none;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 11px 16px;
  font-weight: 500;
  font-size: 13px;
  color: #374957;
  cursor: pointer;
}
.shadow_view_more {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  background: black;
  opacity: 0.6;
}
.view_more {
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.requestBillImage {
  position: relative;
  // height: 80px;
  border-radius: 2px;
}
.requestBillImageM {
  position: relative;
  height: 100px;
  border-radius: 2px;
}

.deleteIcon {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}
