iframe {
  border: 0;
}
body > iframe {
  display: none;
  z-index: -10 !important;
}

figcaption,
figure,
main {
  display: block;
}

main {
  overflow: hidden;
}
