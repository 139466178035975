@import "./variables";
@mixin flexCenter($justifyContent, $alignItems) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin buttonWrapper() {
  padding: 10px 23px;
  font-size: 14px;
  background-color: #72569c;
  border: none;
  cursor: pointer;
  font-weight: 500;
}
