@import "./variables";
@import "./mixins";
@import "./card";

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
}

body {
  font-family: "Poppins", sans-serif;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
  top: 0px !important;
}

p {
  margin: 0;
}

:root {
  --width: 450px;
  --backgroundColor: #f5f5f5;
}

.col_white {
  color: #ffffff;
}

.col_primary_1 {
  color: #2e1a47 !important;
}

.col_primary_2 {
  color: #72569c !important;
}

// body::-webkit-scrollbar {
//   display: none !important;
// }

.bg_light {
  background-color: #fafafa;
}

.sideBar_icon {
  width: 18px;
  margin-right: 13px;

  img {
    width: 100%;
    height: 100%;
  }
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-combo {
  // padding: 9px;
  background: white;
  border: none;
}

.goog-te-combo option {
  padding: 10px;
}

.google_translate_element {
  margin-right: 18px;
  position: relative;
}

.VIpgJd-suEOdc {
  display: none !important;
}

.goog-te-gadget {
  font-size: 0px !important;
  margin-left: 12px !important;
}

.side_bar_logo {
  display: flex;
  justify-content: start;
  align-items: center;
  // margin-bottom: 1.6rem;
  // margin-top: 1.8rem;

  // height: 100%;
  img {
    width: 185px;
    margin-left: 20px;
  }
}

h3,
h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.bg-default {
  background-color: #edf0f8 !important;
}

.bg-transparent {
  text-align: center;
  padding: 1.7rem 1.7rem;
}

.fl-end {
  justify-content: flex-end !important;
}

.login_new_page {
  // position: relative;
  display: flex;
  justify-content: center;
  padding: 0px 40px !important;
  background: transparent !important;
  border: 0 !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.withBackBtn {
  display: flex;
  gap: 15%;
  padding: 0px 40px !important;
  background: transparent !important;
  border: 0 !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.logo-image {
  width: 44%;
}

.empty-card {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bg-secondary {
  background-color: white !important;
}

.nav_bar_right_side {
  display: flex;
  justify-content: flex-end;
  gap: 2%;
  align-items: center;
}

.switch_user {
  background: #ededed;
  border-radius: 45px;
  width: 180px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin-right: 30px;
}

.active_switch {
  background: #ffffff;
  color: #0a8080;
  font-weight: 500;
}

.switch_common {
  width: 83px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  cursor: pointer;
  font-size: 0.875rem;
}

.notification_bell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100% !important;
    height: 100% !important;
  }
}

.notify_badge {
  position: absolute;
  width: 19px;
  height: 19px;
  background: #ed5d51;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 0%;
  transform: translate(50%, -50%);
}

.inactive_switch {
  color: #899aa7;
}

.navBar:after {
  display: none !important;
}

.navBar {
  padding-bottom: 0px !important;
}

.notification-para {
  font-size: 15px;
  color: #515151;
  font-weight: 500;
}

.notification-table-pad .table td {
  padding: 1.2rem 1.5rem 1.2rem 3rem !important;
}

.new-profile-btn {
  font-size: 15px !important;
}

.input-group-alternative {
  border: none;
}

.text-align {
  text-align: right;
}

.text-light {
  color: #5e72e4 !important;
}

a.text-light:hover {
  color: #5e72e4 !important;
}

small {
  font-size: 11px !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

// Tool Tip
.__react_component_tooltip {
  background: white !important;
  color: $secondayColor !important;
  border: 1px solid #2121213b !important;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  opacity: 1 !important;
  padding: 10px !important;
}

.info-text {
  margin-top: 2rem;
  color: $secondayColor;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.toggle-link {
  text-align: center;
  margin-top: 24px;
  font-size: 13px;

  font-family: "Poppins", sans-serif;

  span {
    color: $primaryColor;
    cursor: pointer;
    padding-left: 0.1rem;
    padding-right: 0.2rem;
    font-family: "Poppins", sans-serif;
  }
}

.new-max-width {
  max-width: 780px !important;
}

.pad-log-bot {
  padding-top: 0.7rem !important;
  padding-bottom: 2.5rem !important;
}

.border-0 {
  border-bottom: none;
}

.pad-card-top {
  padding: 2.5rem 1.4rem 1rem !important;
}

.new-pro-design {
  height: 100vh;
  @include flexCenter(center, center);
}

.pad-but {
  padding: 10px 19px !important;
  font-size: 1.325rem !important;
}

.pad-new-in {
  padding: 10px;
}

.pad-new-invite {
  padding: 0.4rem 1.5rem 0.4rem;
  color: rgba(0, 0, 0, 0.5);
}

.sign-auth {
  width: 100%;
  font-size: 1.125rem !important;
}

.radius-log {
  border-radius: 4px;
}

.input-group .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 12px;
}

.navbar-vertical .navbar-nav .nav-link>i {
  min-width: 3rem;
  font-size: 1.5375rem;
}

// Reset Password
.verify-email-max-width {
  max-width: 955px !important;
}

.label-verify {
  color: #555555 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
  font-size: 17px;
  text-align: center;
  line-height: 1.9rem;
}

.verify-flex {
  @include flexCenter(center, center);
  flex-direction: column;
}

.verify-text {
  color: #555555;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.radius-log-verify {
  border-radius: 11px;
}

.success-verify-max-width {
  max-width: 836px !important;
}

.pad-log-bot-verify {
  padding: unset !important;
}

.foot-verify {
  padding: 1.2rem 1.5rem !important;
}

.disable-span {
  color: #3d3c3c75 !important;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
  cursor: not-allowed !important;
}

.receive-email {
  color: #555555;
  font-weight: 400;
  font-family: "Poppins", sans-serif;

  span {
    color: $primaryColor;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-left: 0.2rem;
    cursor: pointer;
  }
}

.margin-level-verify {
  margin-bottom: 1.1rem !important;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: 1rem 1.2rem !important;
  font-size: 1rem;
}

.navbar-vertical::-webkit-scrollbar {
  overflow-y: auto;
  opacity: 0;
}

.navbar-vertical .navbar-nav .nav-link>i {
  min-width: 3rem;
  font-size: 1.2375rem;
}

.image-bot-div {
  border-bottom: 1px solid #00000033;
}

.bg-gradient-info {
  background: linear-gradient(87deg,
      #077e8b70 0,
      $primaryColor 100%) !important;
}

.nav-link i.ni {
  padding-bottom: 0.3rem;
}

.host-name {
  font-size: 1.2rem !important;
}

.resendOTP {
  font-size: 12px;
}

.resendOtpBtn {
  border: 0;
  background-color: transparent;
}

.cursor-point {
  cursor: pointer;
}

.cursor-disable {
  cursor: not-allowed !important;
}

.table td,
.table th {
  font-size: 0.9125rem;
}

h3,
.h3 {
  font-size: 1.3625rem;
}

.pagination {
  justify-content: flex-end !important;
}

.col-xl-8 {
  flex: unset;
  max-width: unset;
}

input[type="checkbox"] {
  cursor: pointer;
}

.word-break {
  white-space: break-spaces;
}

input[type="checkbox"]:after {
  content: " ";
  background-color: $primaryColor;
  color: white;
  visibility: visible;
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  width: 100%;
  height: 100%;
  @include flexCenter(center, center);
  padding: 9px;
  border-radius: 3px;
}

.bg-gradient-default {
  background: linear-gradient(87deg,
      #077e8bd1 0,
      $primaryColor 100%) !important;
}

.btn-info {
  color: #fff;
  background-color: #72569c;
  border: none;
}

.btn-info:hover {
  background-color: $primaryColor !important;
}

.btn-info:active,
.btn-info:focus {
  background-color: $primaryColor !important;
}

.align_center {
  display: flex;
  align-items: center;
}

.justCenter {
  display: flex;
  justify-content: center;
}

.d_flex {
  display: flex;
  border: 1px solid rgba(225, 225, 225, 0.4);
  padding: 9px 10px;
  border-radius: 5px;
}

h5,
.h5 {
  font-size: 1.2125rem;
}

h3 {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.close>span:not(.sr-only) {
  font-size: 2.3rem;
  height: 0rem !important;
}

.pad_new_col {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.font-high {
  font-size: 0.9rem;
}

.row-center {
  justify-content: space-between;
}

.mt-4_5 {
  margin-top: 2.3rem !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.dash-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0px;
}

.dash_left {
  width: 70%;
}

.dash_right {
  width: 49px;
  height: 49px;
}

.heading_with_badge {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.heading_badge {
  i {
    font-size: 0.6rem;
    margin-right: 6px;
  }

  height: 24px;
  background: rgba(240, 101, 72, 0.1);
  border-radius: 3px;
  padding: 2px 4px;
  color: #f06548;
  font-size: 0.625rem !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading_badge_success {
  i {
    font-size: 0.6rem;
    margin-right: 6px;
  }

  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem !important;
  font-weight: 600;
  border-radius: 3px;
  padding: 2px 4px;
  color: #2cb67d;
  background: rgba(44, 182, 125, 0.1);
}

.pad-dash {
  padding: 1.25rem !important;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h4 {
    font-size: 0.875rem !important;
    color: #899aa7;
    font-weight: 600 !important;
    margin-bottom: 0px;
    margin-right: 10px;
  }
}

.dash-new {
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1) !important;
}

.dash_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.row-dash {
  width: 100%;
  justify-content: space-between;
  margin-top: 2.2rem;
  padding: 0 0px 0 22px;
}

.row-dash h4 {
  font-size: $secondayFontSize;
  color: $tertiaryColor;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.dashi {
  color: black;
  font-size: 1.625rem;
  padding-left: 3rem;
}

.fa-greater-than {
  margin-top: 0.5rem;
  font-size: $primaryFontSize;
  color: #e1e1e1;
}

.img-dash {
  width: 100%;
  height: 100%;
}

.dropdown_item {
  img {
    width: 17px !important;
    margin-right: 1rem;
  }
}

.max-dash {
  max-width: 25%;
  transition: 1s;
  padding-left: 10px;
}

.max-dash:first-child {
  padding-left: 15px !important;
}

.max-dash:hover {
  transform: translateY(-5%);
}

.number-con {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 25px;
  line-height: inherit;
  width: 100%;

  span {
    color: #374957 !important;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
  }
}

.main-content {
  position: unset;
  background: #fafafa;
}

// Invoice
.modal-dialog-invoice {
  max-width: 800px;
}

.invoice-title {
  font-size: 35px;
}

.invoice-park {
  width: 13rem;
}

.invoice-header {
  padding: 2.3rem 0rem 2.3rem 0rem !important;
}

.label-invoice {
  margin-top: 1.7rem !important;
  margin-bottom: 1.2rem !important;
}

.prop-detail-para {
  font-size: 17px;
  color: #22212f;
  font-weight: 400;

  font-family: "Poppins", sans-serif;
}

.invoice-body {
  border-bottom: 2px solid #e8e3e373;
  margin: 0 2.5rem 0 2.5rem;
  padding: 0 !important;
}

.label-invoice-prop {
  font-size: 19px !important;
  color: #928f8f !important;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.label-invoice-id {
  font-size: 19px !important;
  color: #393939;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.label-invoice-name {
  font-size: 19px !important;
  color: #22212f;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif;
}

.flex-secondary-invoice {
  margin-bottom: 0 !important;
}

.primary-color {
  color: $primaryColor !important;
}

.primary-background-color {
  background-color: #fafafa !important;
}

.nav-last-panel {
  margin-bottom: 2.3rem !important;
}

.flex-invoice {
  @include flexCenter(space-between, baseline);
}

.bottom-line-invoice {
  margin: 0 2.5rem 0 2.5rem;
  border-bottom: 2px solid #e8e3e373;
}

.main-content .navbar-top {
  position: fixed;
  z-index: 2;
  background-color: #fff !important;
  padding: 0.5rem 0.5rem 0.5rem 1.25rem !important;
  width: inherit;
}

.host-name {
  color: black;
}

.fa-hamburger {
  margin-left: 2rem;
  font-size: 20px;
}

.max-width-rules {
  max-width: 15.33333% !important;
  margin-bottom: 1rem;
}

.table thead th {
  text-transform: none;
}

.border-trans {
  border: none;
  background: transparent;
  color: #5e5e5e !important;
  font-weight: 600;
  width: 185px;
  cursor: pointer;
}

.default-amenity {
  width: 2rem;
}

.mt-ameni {
  margin-top: 0.9rem !important;
}

.opac-hide {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  opacity: 0;
}

.image-hide {
  position: relative;
}

.arrayImg {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.pdfImage {
  position: relative;
}

.billImage {
  width: 80%;
  height: 88%;
}

.card-pad-wrapper {
  padding: 0 29px 0 29px !important;
}

.toastContainer {
  z-index: 99;
}

.ni-fat-remove {
  position: absolute;
  font-size: 2.3rem;
  top: 21%;
  right: 0%;
  cursor: pointer;
}

.purchase-i-remove {
  top: 2px !important;
  right: 10px !important;
}

.purchase-remove {
  top: 9% !important;
}

.pdf-remove {
  position: absolute;
  font-size: 2.3rem;
  right: unset;
  top: unset;
  cursor: pointer;
}

.gallery-change-pad {
  padding-top: 1rem !important;
  min-height: 10vh;
}

.navbar-vertical.navbar-expand-md.fixed-left {
  z-index: 1;
}

// .navbar-top img {
//   width: 13%;
// }
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: 0.9rem 1.3rem;
  font-size: 24px;
}

.mt-8,
.my-8 {
  margin-top: 6rem !important;
}

.mt-7r {
  margin-top: 7rem !important;
}

.avatar-sm {
  width: 32px !important;
  height: 32px !important;
  font-size: 0.875rem;
  object-fit: cover;
}

.icon_side {
  margin-right: 1rem;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: 1.4rem 3rem;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.side_name {
  margin: 0;
  font-size: 14px;
  // margin-top: 5px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-weight: 500;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: $primaryColor;
  background-color: $primaryColor;
}

.green {
  color: #008000 !important;
}

.button_spinner {
  .spinner-border {
    color: #dfeced !important;
  }

  .spinner-border {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.disable_button {
  pointer-events: none;
}

.select_customized_div {
  label {
    margin-bottom: 14px;
  }

  display: flex;
  flex-direction: column !important;

  .select_customer {
    width: 400px;
    border-bottom: 1px solid #e1e1e1;
    background: transparent;
    box-shadow: none;
  }

  button {
    box-shadow: none !important;
    background: white !important;
    border: none !important;
    padding-left: 0px;
    font-weight: 400;
  }

  .full_width_options {
    width: 100%;
  }
}

.red {
  color: #f83245 !important;
}

.yellow {
  color: #ffc043 !important;
}

.blue {
  color: rgb(52, 152, 251) !important;
}

.primary {
  color: $primaryColor !important;
}

.change-notice-pass {
  min-width: 63px !important;
  padding: 5px 6px !important;
}

.accept-notice {
  margin-top: unset !important;
}

.data-wrap {
  display: flex;
}

.change-notice-pass label {
  font-size: 14px !important;
}

.accept-wrap {
  color: #008000 !important;
  border: 1px solid #008000 !important;
}

.reject-wrap {
  color: #f83245 !important;
  border: 1px solid #f83245 !important;
}

.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after {
  cursor: pointer;
}

.custom-switch {
  z-index: auto;
}

.pad-to-8 {
  padding-top: 0.8rem;
}

// .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
//   border-left: none !important;
//   content: "";
//   position: absolute;
//   right: 0px;
//   top: 0px;
//   width: 5px;
//   background-color: white;
//   height: 100%;
//   border-top-right-radius: 4px;
//   border-bottom-right-radius: 4px;
// }

.navbar-vertical {
  // background: linear-gradient(
  //   92.04deg,
  //   rgba(255, 255, 255, 0.57) 1.33%,
  //   #f5f5f5 97.9%
  // );
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.nav-active {
  // border-left: 5px solid $primaryColor !important;
  // background: transparent;
  background-color: #ffffff;
  width: 100%;
}

.nav-SubChildActive {}

.nav-SubChildActive .side_name {
  color: #ffffff;
}

.nav-active .side_name {
  // color: $primaryColor;
  color: #ffffff;
}

.nav-inactive {
  background: transparent;
  border-left: none;
  width: 100%;
}

.nav-inactive:hover {
  background-color: #3c235b;
}

.navbar-vertical .navbar-nav .nav-link.active>.side_name {
  font-size: 14px;
  // color: $primaryColor;
  font-family: "Poppins", sans-serif;
  color: #2e1a47;
  font-weight: 500;
}

.side_dark {
  font-weight: 600;
  color: $primaryColor;
}

.side_bar_data {
  margin-bottom: 10px;

  .nav-link {
    height: 45px;
  }

  width: 100%;
  padding: 0rem;
}

.container_box {
  // display: flex !important;
  display: grid !important;
  grid-template-columns: 10% 30%;
  padding: 0px !important;

  .toogle_side_bar {
    display: grid;
    grid-template-columns: 10% 90%;
    // display: flex;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    gap: 0%;

    img {
      cursor: pointer;
      // width: 100%;
      // height: 100%;
    }
  }
}

.no_side_bar_data {
  display: none;
}

.small_side_bar {
  .navbar-vertical {
    width: 4% !important;
    padding-right: 0px;
  }

  .icon_side {
    margin-right: 0px;
  }

  .main-content {
    width: 96% !important;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .no_side_bar_data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-direction: column;
    margin: 0px;

    .nav-link {
      padding: 1.4rem 0rem !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0px !important;
    }

    .navbar-nav li {
      width: 100%;
      margin-bottom: 20px;
    }

    .navbar-nav {
      width: 100%;
    }

    .navbar-vertical .navbar-nav {
      margin-left: 0px !important;
      margin-right: 6px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .navbar-vertical.navbar-expand-md.fixed-left {
      padding-right: 0px !important;
    }
  }

  .side_bar_data {
    display: none;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

.navbar-vertical {
  transition: 0.5s all;
}

.navbar_options {
  li {
    margin-right: 0px;
    padding-top: 0px;
    padding-left: 0px;
  }
}

.navbar-vertical .navbar-collapse:before {
  margin: unset;
}

.mt-7px {
  margin-top: 7px;
}

.mt-7 {
  margin-top: 9rem !important;
}

.breadcrumb {
  background-color: transparent;
  margin: 0;
  padding: 0 0;
}

.managers-crumb {
  color: #7b7b7b;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.manage-con {
  padding-left: 0;
  padding-right: 0;
}

.button-wrapping {
  @include buttonWrapper();
}

.button-wrapping:hover {
  background-color: $primaryColor !important;
  color: white;
}

.reset-btn {
  padding: 10px 19px;
  font-size: 15px;
  max-height: 45px;
  box-shadow: unset !important;
}

.flexy-content {
  display: flex;
}

.start-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flexy-content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-reset-pass {
  min-width: 89px !important;
  padding: 10px 6px !important;
  max-height: 45px;
}

.reset-prop label {
  font-size: 15px !important;
}

.header-div {
  margin: 1.3rem 0 0rem 0;
}

.header-div-no {
  margin: 0 0 1.8rem 0;
}

.card .table td,
.card .table th {
  padding-left: 1.7rem;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}

.card .table th {
  font-family: "Poppins", sans-serif;
  font-weight: 500 !important;
  color: #72569c !important;
  font-size: 13px !important;
  background: #fafafa;
  border-color: #e1e8f0;
  border: 0.5px 0px 0.5px 0px;
}

.request-flex {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.req_form_wrap {
  width: 44%;
}

.req_form_wrap_first {
  width: 53%;
}

.request-flow-card {
  max-height: 675px;
}

.request-input {
  border-bottom: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.new-border-request {
  border-bottom: 1px solid #e1e1e1 !important;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  border-left: 0;
}

.pro-wrap {
  font-size: $primaryFontSize;
}

.bank-text {
  color: #c8bfbf !important;
}

.edit-pro-prop {
  min-width: 102px !important;
  padding: 6px 15px !important;
}

.min-width-drop {
  min-width: 8rem !important;
}

.fa-info-circle {
  font-size: 22px;
  cursor: pointer;
}

.inherit-request-flex {
  flex-direction: column;
}

.form-req {
  display: flex;
  width: 100%;
}

.request-width {
  width: 100%;
}

.request-line {
  color: $tertiaryColor;
  font-size: $primaryFontSize;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.request-inactive-line {
  color: #c4c4c4;
  font-size: $primaryFontSize;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.request-high {
  width: 100%;
}

.request-flexy-flex {
  display: flex;
  justify-content: space-between;
}

.table-flush tbody tr td {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  // padding-top: 0.8rem;
  // padding-bottom: 0.8rem;
}

.card .table td {
  padding-bottom: 0.9rem;
  padding-top: 0.9rem;
  font-size: 13px;
  color: #212121;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

// .card .table td .no_padding {
//   padding: 0px !important;
// }

.form-control-label-ameni {
  color: $tertiaryColor;
  font-size: 19px;
  margin-bottom: 0;
  margin-top: 0.5rem;
  margin-left: 1rem;
  font-size: 20px;
}

.equal_header_padding {
  padding-top: 2.75rem !important;
}

.dashboard_design_padding {
  padding-top: 0rem !important;
  padding-bottom: 1.8rem !important;
}

input[type="checkbox"]:checked {
  background: rgba(0, 0, 0, 0);
}

.col-edit {
  max-width: 8.33333% !important;
}

.edit-prop {
  min-width: 102px !important;
  padding: 6px 6px !important;
}

.change-profile-pass {
  min-width: 78px !important;
  padding: 7px 6px !important;
}

.change-profile-pass .profile-cancel {
  font-size: 15px !important;
  margin-bottom: unset !important;
  margin-top: unset !important;
}

.new-date-in {
  border-top: 1px solid $tertiaryColor !important;
  border-bottom: 1px solid $tertiaryColor !important;
  border-left: 1px solid $tertiaryColor !important;
  border-radius: 5px 0px 0px 5px !important;
}

.notice-group {
  margin-right: 0 !important;
  border-top: 1px solid $tertiaryColor !important;
  border-bottom: 1px solid $tertiaryColor !important;
  border-right: 1px solid $tertiaryColor !important;
  border-radius: 0px 5px 5px 0px !important;
  background: rgba(7, 126, 139, 0.1);
}

.modal-dialog-notice {
  max-width: 500px;
}

.modal-footer-notice {
  padding-top: 0;
}

.nav-notice {
  padding: 0 !important;
}

.label-accept {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.label-accept label {
  font-size: $secondayFontSize !important;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
  color: white;
}

.spin-loading-profile-wait .spinner-border {
  width: 5rem;
  height: 5rem;
  color: $primaryColor !important;
}

.spin-loading .spinner-border {
  color: $primaryColor !important;
}

.spin-loading3 .spinner-border {
  color: $primaryColor !important;
}

.spin-loading3 {
  @include flexCenter(center, center);
  height: 100%;
}

.width-on-load {
  min-width: 143px !important;
}

.up-prop-btn {
  min-width: 96px !important;
}

.modal-row .modal-content {
  min-height: 182px;
  @include flexCenter(center, center);
}

.width-invite {
  width: 7% !important;

  img {
    width: 73% !important;
  }
}

.bank-body {
  @include flexCenter(center, center);
}

.notice-date .react-datepicker-wrapper {
  width: 100% !important;
}

.change-profile-button {
  padding: 8px 23px;
  font-size: $primaryFontSize;
}

.display-none {
  display: none;
}

.notify-alter {
  padding: 15px !important;
  border: 1px solid #e9e9e9;
}

.edit-prop label {
  font-size: 15px !important;
}

// Timeline

.css-ha3bif-MuiTimelineItem-root:before {
  display: none;
}

.css-1rcbby2::before {
  display: none;
}

.css-1nqcc1s {
  border-radius: 13px !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.css-v6mo1y-MuiTimelineDot-root {
  border-radius: 13px !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.css-1rcbby2 {
  min-height: 130px !important;
}

.css-ha3bif-MuiTimelineItem-root {
  min-height: 130px !important;
}

.pad-left-0-8 {
  padding-left: 0px !important;
}

.timeline-inherit {
  color: $tertiaryColor;
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
}

.progress-tick-image {
  padding: 6px 5px !important;
}

.progress-image {
  padding: 18px 22px !important;
}

.progress-active-image {
  padding: 4px 4px !important;
}

.request-active-line {
  background-color: #51ca06 !important;
}

.request-red-line {
  background-color: #f83245 !important;
}

.reject-wrapping {
  background-color: unset !important;
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  padding: 9px 23px !important;
}

.reject-wrapping:hover {
  background-color: unset !important;
}

.request-cancel-flow-card {
  max-height: 286px;
}

.mb-0-7 {
  margin-bottom: 0.7rem !important;
}

.form-control-label {
  color: $tertiaryColor;
  font-size: 14px;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.request-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.card-header:first-child {
  padding-left: 3rem;
}

.new-card {
  padding-left: 3rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

h3,
.h3 {
  font-size: 1.1625rem;
}

.form-control-alternative {
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
  color: black;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.form-control-alternative:focus {
  box-shadow: none;
  border-bottom: 1px solid black;
  color: #22212f;
}

.bread-cursor {
  cursor: pointer;
}

.footer {
  display: none;
}

.border-img-park {
  object-fit: cover;
  border-radius: 8px;
  width: 75px;
  height: 75px;
  cursor: pointer;
}

.image-flexor {
  display: flex;
  margin-right: 0.938rem;
}

.row-photos {
  padding-left: 15px;
}

.title {
  width: 82%;
  white-space: unset !important;
}

.loader-btn {
  @include flexCenter(center, center);
}

.loader-btn-reverse {
  min-width: 175px !important;
}

.spinner-btn {
  .spinner-border {
    color: #2e1a47 !important;
  }

  .spinner-border {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.login_spinner {
  .spinner-border {
    color: #ffffff !important;
  }

  .spinner-border {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.send-disable {
  color: #6363637a;
  cursor: not-allowed;
}

.bg-shadowable {
  background-color: #72569c !important;
  border: none !important;
}

.reject-text-area {
  width: 100%;
  height: 112px;
  border: 1px solid #75757569;
  padding: 10px;
}

.reject-text-area::placeholder {
  color: #64626259;
}

.text-area-add-project {
  border: 1px solid rgb(202, 209, 215);
  border-radius: 0.375rem;
  width: 100%;
  height: 112px;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }
}

.title-head {
  color: black;
  font-weight: 500;
  margin-bottom: 0.7rem !important;
}

.notify-alter {
  border-bottom: 0;
  padding: 32px;
  font-size: 20px;
  border-radius: 7px;
  width: 100%;
}

.text-notify {
  padding-top: 1.3rem;
  height: 140px;
}

.text-notify::placeholder {
  color: #a8a7a7;
}

.form-control-alternative::placeholder {
  padding: 0;
}

.pad-new-page {
  padding-right: unset !important;
}

.jus-space {
  justify-content: space-between;
}

.justCenterColoum {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.paginate-flex-box {
  display: flex;
  justify-content: center;
  width: 14%;
  gap: 4%;
  margin-right: 9px;
}

.paginate-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 7px;
  padding: 6px 15px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500 !important;
  margin-right: 20px;
}

.paginate-box:last-child {
  margin-right: 0px;
}

// Notice Detail
.secondary-color-none {
  background: unset !important;
  padding: 1.8rem 0.8rem 0 1.8rem !important;
  padding-left: 2rem !important;
}

.notice-image-wrapper {
  flex: 0 0 14.66667% !important;
  max-width: 13.66667% !important;
}

.call-message {
  padding: 20px 0 0px;
}

.notice-det-pad {
  padding: 1.2rem 1.2rem;
}

.notice-det-pad-sec {
  padding: 2.8rem 1.2rem;
}

.font-vary {
  font-size: 1.0625rem !important;
}

.notice-body {
  padding: 0 !important;
  border-bottom: 2px solid #e8e3e373;
}

.label-notice-prop {
  font-size: 1rem !important;
  color: #636363 !important;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.label-notice-id {
  font-size: 0.938rem !important;
  color: #393939;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.notice-detail-para {
  color: #636363;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
}

.notice-flex-row {
  display: flex;
  align-items: center;

  img {
    margin-right: 1.4rem;
    width: 1.2rem;
  }

  label {
    margin-top: 0.2rem;
    color: #373d3f;

    font-family: "Poppins", sans-serif;
    font-weight: 400 !important;
  }
}

.notice-flex-row:nth-child(2) {
  margin-top: 0.7rem;
}

.card-not-pad {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.proper-img {
  max-height: 68%;
}

.row-image-notice {
  height: 120px;
}

.notice-imageprop-wrapper {
  flex: 0 0 18.66667% !important;
  max-width: 16.66667% !important;
}

.ma-r {
  margin-right: 1rem;
}

.fa-angle-left {
  font-size: 23px;
  cursor: pointer;
  color: $primaryColor;
}

.fa-angle-right {
  font-size: 23px;
  cursor: pointer;
  color: $primaryColor;
}

.disabled {
  color: #00000063;
  pointer-events: none;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.spinner-borders {
  display: inline-block;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.spin-loading {
  @include flexCenter(center, center);
  height: 100vh;
}

.spin {
  height: 100% !important;
}

.image-loading {
  // border: 1px solid #0000003b;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  @include flexCenter(center, center);
  color: $primaryColor !important;
  // margin-bottom: 10px;
}

.image-loading .spinner-border {
  color: $primaryColor;
}

.no-data {
  @include flexCenter(flex-start, center);
  font-size: 0.875rem;
  font-weight: 400;
  color: #24242491;
  font-family: "Poppins", sans-serif;
  padding: 15px;
  padding-left: 46px;
}

.none_event {
  pointer-events: none;
}

.hamburger_nav {
  color: #7b7b7b;
  cursor: pointer;
}

// Mutiple Select

.css-1s2u09g-control {
  border-bottom: 1px solid #e1e1e1 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}

.css-319lph-ValueContainer {
  font-size: 15px !important;

  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
  color: #22212f !important;
}

.css-1s2u09g-control {
  margin-top: 0.45rem;
}

.css-tlfecz-indicatorContainer {
  color: #22212f !important;
}

.css-1pahdxg-control {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #22212f !important;
  box-shadow: unset !important;
  border-radius: 0 !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-g1d714-ValueContainer {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.image-1oading .spinner-border {
  height: 1.2rem !important;
  width: 1.2rem !important;
}

.form-control-alternative {
  padding: 0;
}

.form-control-alternative .noti:focus {
  border-bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.select-notify {
  width: 20%;
  background: white;
  height: 64px;
  padding: 13px;
  color: #a8a7a7;
  padding-left: 2rem;
}

.btn-exceed {
  max-width: 10.33333% !important;
}

.filter-wrap {
  background: transparent;
  color: $primaryColor;
  font-weight: 700;
  min-width: 113px;
  @include flexCenter(center, center);
  padding: 10px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.new-send-invite {
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-left: 1px solid #e1e1e1 !important;
}

.invite-group {
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-right: 1px solid #e1e1e1 !important;
}

.pad-invite {
  padding: 0.4rem 1.5rem 1.7rem;
  color: rgba(0, 0, 0, 0.5);
}

.font-24 {
  font-size: 24px;
}

.modal-invite {
  padding: 20px;
}

.invite-email-in {
  border-right: 1px solid #e1e1e1 !important;
}

.copy-navigator {
  width: 92%;
  @include flexCenter(space-between, center);
  background: #fafafa;
  padding: 10px;
}

.copy-navigator p {
  color: $tertiaryColor;
}

.fade-color {
  margin-top: 0.7rem;
  color: $tertiaryColor;
}

.success-invite {
  flex-direction: column;
  @include flexCenter(center, center);
  padding: 1.8rem 1.5rem 2.5rem;
}

.label-invite {
  font-size: 15px !important;
  margin-top: 0.5rem !important;
  color: #22212f !important;
}

.fade_effect {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.filter-wrap:hover {
  background-color: transparent !important;
  color: $primaryColor;
}

.filter-wrap img {
  width: 16%;
  margin-right: 0.6rem;
  margin-top: 0.1rem;
}

.filter-wrap label {
  font-size: 17px;
  margin-bottom: 0.1rem;
  cursor: pointer;
  margin-top: 0.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.input-filter-toggle {
  border: 1px solid #e1e8f0 !important;
  height: 45px;
  border-radius: 0 5px 5px 0px;
}

.change-password-btn label {
  font-size: 15px !important;
}

.form_select {
  padding: 11px 18px;
  font-size: $primaryFontSize;
  background: #f5f5f5;
  border: none;
  font-size: 14px !important;
  border-radius: 5px 0 0 5px;
  color: #374957;
  height: 100%;
}

.reset-btn:not(:disabled):not(.disabled):active {
  color: $primaryColor !important;
}

.form_drop {
  padding: 11px 23px;
  font-size: $primaryFontSize;
  background: #f5f5f5;
  border: none;
  margin-right: 4rem;
  border-radius: 5px;
  width: 13%;
  height: 48%;
}

.min-load {
  min-width: 176px;
  background-color: $primaryColor !important;
  padding: 10px 23px;
  border: none !important;
}

.spinner-cut {
  border: unset !important;
}

.form_drop,
.form_select {
  -webkit-appearance: none;
  appearance: none;
}

.notification-label {
  font-size: 20px !important;
  color: #434343 !important;
}

.select-notification {
  display: flex;
  flex-direction: column;
}

select.form_drop,
select.form_select {
  background-position: calc(100% - 20px) calc(1em + 5px),
    calc(100% - 15px) calc(1em + 5px), calc(100% - 2.5em) 0.8em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.form-control[readonly] {
  background-color: transparent;
}

.profile-img-content {
  @include flexCenter(flex-start, center);
}

// Date Picker
.react-datepicker__day--selected {
  background-color: $primaryColor !important;
}

.sphere-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
}

.label-wrap-pro {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.input-group-select {
  width: 20%;
}

.input-group-select .form_select {
  width: 100%;
}

.name-profile {
  font-size: $secondayFontSize;
  font-weight: 600;
  color: #22212f;
  margin-bottom: 0;
  font-size: 17px !important;
}

.email-profile {
  font-size: $primaryFontSize;
  font-weight: 500;
  color: $tertiaryColor;
}

.pad-left-profile {
  padding-bottom: 1.4rem;
}

.pencil-bg {
  position: absolute;
  height: 23px;
  width: 23px;
  text-align: center;
  border-radius: 50%;
  right: 0;
  top: 50%;
  transform: translate(-50%, 10px);
  background-color: $primaryColor;
  cursor: pointer;

  .fa-pencil-alt {
    color: white;
    font-size: 11px;
  }
}

.sphere-image img {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.profile-btn-img {
  position: absolute;
  opacity: 0%;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.filter_room_wrap {
  padding: 6px 10px;
}

.flex-content {
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex-wrap: nowrap;
}

.align-left {
  justify-content: flex-start !important;
}

.tex-area-prop {
  line-height: 2rem;
  font-size: 15px;
}

.search-wrapper {
  font-size: $primaryFontSize;
  padding: 6px 23px;
  margin-left: 4.5rem;
}

.hide-content {
  display: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: $primaryColor;
  background-color: unset;
  border-color: $primaryColor;
}

.btn-primary {
  box-shadow: none !important;
}

.filter-input-date {
  height: 46px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #f5f5f5;
  border-right: none;
  color: #374957;
  // border-bottom: 1px solid #e1e1e1;
  border-color: #e1e8f0;
  border-width: 1px 0px 1px 1px;
}

.to_filter-input-date {
  height: 46px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border-color: #e1e8f0;
  border-width: 0px 1px 1px 0px;
  color: #374957;
  border-top: 1px solid #e1e8f0;
  border-radius: 0px 5px 5px 0px;
}

.group-date {
  border: 1px solid #f5f5f5;
  height: 46px;
  text-align: center;
  padding: 16px !important;
  border-radius: 0px;
  border-left: none;
}

// .react-datepicker-wrapper {
//   width: 11.5% !important;
// }
.date-svg {
  width: 16px;
}

.content-flex {
  margin-left: 2.5rem !important;
}

.input-more {
  max-width: 14%;
}

.drop_more {
  margin-right: 2.5rem;
  width: 12.5%;
}

.date-merge {
  min-width: fit-content;
  padding: 10px;
  color: #374957;
  height: 46px;
  background: white;
  @include flexCenter(center, center);
  font-size: 0.875rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #e1e8f0;
  border-right: none;
}

.todate-merge {
  min-width: fit-content;
  padding: 10px;
  color: #9ba5ac;
  font-size: 13px;
  font-weight: 300;
  height: 46px;
  background: #ffffff;
  @include flexCenter(center, center);
  font-size: 0.875rem;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

.mb-remove {
  margin-bottom: 0 !important;
}

.add-filter-wrap {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
}

.progress-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $primaryColor;
}

.progress-div {
  width: 85%;
  height: 4px;
  margin: auto;
  background-color: #c4c4c4;
  position: relative;
}

.progress-back-toggle {
  height: 100%;
  width: 0%;
  background-color: $primary_body_color;
  transition: 1.5s;
}

.w_15 {
  width: 15%;
}

.w_16 {
  width: 16%;
}

.w_18 {
  width: 18%;
}

.w_13 {
  width: 13%;
}

.w_14 {
  width: 14%;
}

.w_20 {
  width: 20%;
}

.w_25 {
  width: 25%;
}

.w_30 {
  width: 30%;
}

.w_40 {
  width: 40%;
}

.w_3 {
  width: 33%;
}

.w_4 {
  width: 4%;
}

.w_8 {
  width: 8%;
}

.w_6 {
  width: 66.6%;
}

.w_9 {
  width: 100%;
}

.w_2 {
  width: 2%;
}

.w_2_5 {
  width: 2.5%;
}

.w_3_rooms {
  width: 50%;
}

.w_6_rooms {
  width: 100%;
}

.w_0 {
  width: 0%;
}

.steps-1,
.steps-2,
.steps-3,
.steps-4 {
  width: 22px;
  height: 22px;
  background-color: #c4c4c4;
  border-radius: 50%;
}

.steps-1-rooms,
.steps-2-rooms,
.steps-3-rooms,
.steps-4-rooms {
  width: 22px;
  height: 22px;
  background-color: #c4c4c4;
  border-radius: 50%;
}

.back {
  background-color: $primary_body_color;
  width: 38px;
  height: 38px;
  @include flexCenter(center, center);
}

.img-abs-prop {
  width: 51%;
}

.pad-r-1-5 {
  padding-right: 1.5rem !important;
}

.pad-r-3 {
  padding-right: 3rem !important;
}

.profile-body {
  padding-top: 0;
  padding-bottom: 0;
}

.img-black {
  background-color: $primary_body_color;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;

  @include flexCenter(center, center);
  transition: 1.6s;
}

.image_one {
  left: 0;
  transform: translate(-50%, -41%);
}

.image_two {
  left: 33.3%;
  transform: translate(-50%, -41%);
}

.image_three {
  left: 66.6%;
  transform: translate(-50%, -41%);
}

.image_four {
  left: 100%;
  transform: translate(-50%, -41%);
}

.image_one_rooms {
  left: 0;
  transform: translate(-50%, -41%);
}

.image_two_rooms {
  left: 50%;
  transform: translate(-50%, -41%);
}

.image_three_rooms {
  left: 100%;
  transform: translate(-50%, -41%);
}

.img-abs {
  width: 61%;
}

.steps-1 {
  position: absolute;
  left: 0;
  transform: translate(-50%, -41%);
}

.steps-1-rooms {
  position: absolute;
  left: 0;
  transform: translate(-50%, -41%);
}

.steps-2-rooms {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -41%);
}

.steps-3-rooms {
  position: absolute;
  left: 100%;
  transform: translate(-50%, -41%);
}

.steps-2 {
  position: absolute;
  left: 33.3%;
  transform: translate(-50%, -41%);
}

.steps-3 {
  position: absolute;
  left: 66.6%;
  transform: translate(-50%, -41%);
}

.steps-4 {
  position: absolute;
  left: 100%;
  transform: translate(-50%, -41%);
}

.main-content .container-sm,
.main-content .container-md,
.main-content .container-lg,
.main-content .container-xl {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.form-check-input {
  position: absolute;
  margin-top: 0.5rem;
  margin-left: -1.9rem;
  width: 20px;
  height: 20px;
}

.delete-icon {
  width: 9%;
  cursor: pointer;
}

.edit-icon {
  margin-right: 3rem;
  margin-top: 0.2rem;
  width: 15%;
  cursor: pointer;
}

.dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2333333345' stroke-width='5' stroke-dasharray='8%2c 18' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  height: 151px;
  @include flexCenter(center, center);
  flex-direction: column;
  cursor: pointer;
}

.img-adding {
  width: 67%;
}

.modal-title {
  color: #22212f;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.dashed-border .label-wrap {
  font-size: 22px;
  color: #c4c4c4;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  cursor: pointer;
}

.label-wrap label {
  cursor: pointer;
  font-size: $primaryFontSize;
  margin-right: 14px;
}

.card-header {
  padding: 0.8rem 1.5rem !important;
  background: #72569c;

  h3 {
    font-size: 15px !important;
    color: #ffffff !important;
  }
}

.progress-all {
  padding-top: 2rem;
  padding-bottom: 0rem;
}

.inputing {
  max-width: 19%;
}

.mr-2-5 {
  margin-right: 2.5rem !important;
}

.button-secondary {
  background-color: unset !important;
  color: $primaryColor;
}

.button-secondary:hover {
  background-color: unset !important;
  color: $primaryColor;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef;
}

.drop_down_container {
  box-shadow: none;
  width: 100%;
  height: 100%;
  background: transparent;
}

.form-relative {
  position: relative;
}

.loc_abs {
  position: absolute;
  width: 7%;
  right: 0;
  top: 88%;
  transform: translate(-50%, -110%);
  cursor: pointer;
  background: #ffffff;
  padding: 3px 8px;
}

// Map Moal
.map-modal {
  position: relative;
  width: 100% !important;
  min-height: 75vh !important;
}

.map-modal-address {
  min-height: 30px;
  padding: 0 0 0 15px;
  /* developer css */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
}

.map-modal-address label {
  float: right;
  padding: 10px;
  width: 40px;
  font-size: $primaryFontSize;
  text-align: center;
  border-radius: 0 4px 0 0;
  margin: 0px;
}

.map-modal-address label:hover {
  cursor: pointer;
  background-color: rgb(0, 148, 148);
}

.map-modal-address h3 {
  float: left;
  padding: 10px;
  font-size: $primaryFontSize;
  margin: 0px;
}

.sm__modal {
  position: absolute !important;
  top: 60px;
  left: 15px;
  z-index: 100;
  width: 370px;
  height: 40px;
}

.sm__modal input {
  width: 290px;
  height: 100%;
  padding: 1rem;
  border-radius: 4px 0 0 4px;
  border: 1px solid grey;
  transition: 0.3s all;
}

.sm__modal input:focus {
  border-color: #2e75cf;
}

.sm__modal button {
  width: 80px;
  height: 100%;
  border: 1px solid teal;
  background-color: teal;
  color: #fff;
  border-radius: 0 4px 4px 0;
  transition: 0.3s all;
}

.sm__modal ul {
  background-color: #fff;
  list-style: none;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  max-height: 250px;
  overflow-y: scroll;
  padding-left: 0;
  cursor: pointer;
}

.sm__modal ul li {
  padding: 5px 15px;
  border-bottom: 1px solid #00000014;
  color: rgba(0, 0, 0, 0.5);
}

.sm__modal ul li:hover {
  background: rgba(7, 126, 139, 0.1);
}

.sm__modal::-webkit-scrollbar {
  width: 0px;
}

.map-marker {
  max-width: 1000px;
  min-height: 500px;
}

.map-modal {
  min-height: 60vh;
}

.button-wrapping:not(:disabled):not(.disabled):active {
  color: white;
}

.sec-btn-wrapping:not(:disabled):not(.disabled):active {
  color: $primaryColor !important;
}

// Tab Pane
.nav-tabs .nav-link.active {
  background: #fafafa;
  color: $secondayColor;
  padding: 10px 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.nav-tabs .nav-link {
  color: #262626;
  padding: 10px 50px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
}

.tab-prop-img {
  width: 210px;
  height: 140px;
  object-fit: cover;
  border-radius: 10px;
}

.new-send-book {
  color: $tertiaryColor;
  height: 40px;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-left: 1px solid #e1e1e1 !important;
}

.profile-book-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.flex-card-book {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 2.5rem;
}

.image-card-book {
  width: 22%;
}

.info-book {
  width: 78%;
  height: 0;
}

.header-div-book {
  margin: 0rem 0 1.8rem 0;
}

.basic-data {
  color: #262626;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 45%;
  margin-bottom: 0.9rem;
  font-size: 14px;
}

.basic-data-payment {
  color: $secondayColor;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 36%;
}

.book-row-basic {
  width: 100%;
}

.prof-book-name {
  color: $secondayColor;
}

.basic-info-name {
  color: $tertiaryColor;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.basic-info-name-payment {
  color: $tertiaryColor;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.pad-book-phone {
  padding-top: 0.7rem;
}

.content-pad-book {
  padding-left: 3rem !important;
}

.book-name-font {
  font-size: 16px !important;
}

.font-book {
  font-size: 14px !important;
}

.new-card-book {
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.booking_card h3 {
  font-size: 1rem !important;
}

.wrap-card-book {
  padding: 42px 45px;
  max-width: fit-content;
  border: 1px solid #dedede;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.designation-book {
  color: #636363;
  font-size: 17px;
}

.name-book {
  font-size: 1rem;
}

.image-tab-wrap {
  display: flex;
}

.prop-tab-content {
  height: 85%;
  padding: 0rem 1.5rem;
}

.add-doc {
  color: $primaryColor;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.add-Doc-img {
  width: 1rem;
  margin-bottom: 0.2rem;
}

.check-date-flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.check-separate-col {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-book-label {
  width: inherit;
  color: $secondayColor;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.book-pad {
  padding: 1rem;
}

.back-unset {
  background-color: unset !important;
  height: 40px;
}

.login-flexing,
.forgot-trans {
  flex: 0 0 58.66667%;
  max-width: 58.66667%;
  transition: all 0.5s;
}

.line-2 {
  line-height: 1.7rem;
}

.notification-schedule-info {
  display: flex;
  width: 42%;
  justify-content: space-between;
}

.additional-message,
.additional-message-info {
  padding: 8px 12px;
  border: 10px;
  border-radius: 5px;

  label {
    font-family: "Poppins", sans-serif;
    font-weight: 500 !important;
    font-size: 13px;
  }
}

.additional-message {
  background-color: rgba(7, 126, 139, 0.15);

  label {
    color: $primaryColor;
  }
}

.additional-message-info {
  background-color: #f4f4f4;

  label {
    color: #7b7b7b;
  }
}

.mt-0-8 {
  margin-top: 0.9rem;
}

.mt--1-2 {
  margin-top: 1.5rem !important;
}

.term-color {
  color: $primaryColor;
  font-weight: 600;
  cursor: pointer;
}

.email-new-in {
  padding: 1.025rem 0.65rem;
  font-size: 15px;
  color: #22212f !important;
  border: 1px solid #e6e6e6 !important;
  border-radius: 10px;
}

.new-border {
  border: 1px solid #e1e1e1 !important;
}

.signup-btn {
  width: 30%;
  padding: 10px 18px;
  font-size: 1.125rem !important;
  max-width: 27%;
  min-width: 25%;
}

.pad-left-right-2 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.label-in {
  color: #22212f !important;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.mt-4-5 {
  margin-top: 1.5rem;
}

.login-signup {
  flex: 0 0 96.66667% !important;
  max-width: 99.66667% !important;
  transition: all 0.5s;
}

// .signup-flexy {
//   // display: flex;
//   // justify-content: space-between;
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-gap: 20px;

// }
.inherit-flexy {
  width: 100%;
}

.signup-btn {
  width: 30%;
  padding: 10px 18px;
  font-size: 1.125rem !important;
}

.logo-sign-image {
  width: 25%;
}

.mb-term {
  margin-top: 0.6rem;
  padding-left: 1rem;
}

.terms-pad {
  padding-left: 0.8rem;
}

.term-label {
  font-size: 13px;
  margin-top: 0.7rem;

  font-family: "Poppins", sans-serif;
  color: rgba(107, 105, 105, 0.75);
}

.term-in {
  height: 18px;
  margin-top: 0.64rem;
}

.mb-0-7 {
  margin-bottom: 0.7rem !important;
}

.signup-nav {
  padding: 2.5rem 33px 20px;
  @include flexCenter(space-between, center);
}

.signin-small {
  font-size: 22px !important;
  font-weight: 500;
  color: black;
  font-family: "Poppins", sans-serif;
}

.flex-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.new-input-login {
  box-shadow: none;
}

.new-login-in {
  border: 1px solid #e1e1e1;
}

.checking-in>input[type="checkbox"] {
  display: none;
}

.checking-in>input[type="checkbox"]+*::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}

.checking-in>input[type="checkbox"]:checked+*::before {
  content: "✓";
  color: white;
  text-align: center;
  background: teal;
  border-color: teal;
}

.checking-in>input[type="checkbox"]:checked+* {
  color: teal;
}

.mb_40 h2 {
  padding-bottom: 1.25rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.563rem;
  font-weight: 500;
  color: $primaryColor;
  margin: 0;
}

.mb_40 h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: $primaryColor;
  margin: 1rem 0;
}

.mb_40 p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: $tertiaryColor;
  line-height: 2.188rem;
  font-size: 1.125rem;
}

.mb_40 li {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: $tertiaryColor;
  line-height: 2.188rem;
  font-size: 1.125rem;
}

.status_div_invite {
  display: flex;

  p {
    text-transform: capitalize;
  }

  .status_div_invite p:first-child {
    margin-right: 40px;
  }
}

.main_container_terms {
  max-width: 1440px;
  margin: 0rem auto;
  padding: 0px 85px;
}

.main-bg-content {
  background-color: white !important;
  width: 100vw;
}

.terms-design {
  display: flex;
  justify-content: center;
}

.terms-logo {
  padding: 33px 0;

  img {
    width: 13rem;
    cursor: pointer;
  }
}

// Toast
.top-right {
  top: 12px;
  right: 50%;
  transform: translateX(32%);
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-center {
  top: 12px;
  right: 50%;
  transform: translateX(26%);
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  animation: animate_div 0.6s 1;
  transition: 3s all;
}

.bottom-left-toast {
  bottom: 12px !important;
  left: 1%;
  animation: animate_div 0.6s 1;
  transition: 3s all;
}

.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 2000;
  animation: animate_div 0.6s 1;
  transition: 3s all;
}

.toast {
  display: flex;
  padding: 20px 25px !important;
  min-width: 345px !important;
  max-width: unset !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 5px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: animate_div 0.6s 1;
  transition: 3s all;
  font-size: 11px !important;
}

@keyframes animate_div {
  0% {
    top: -100%;
  }

  100% {
    top: 12px;
  }
}

.closeToast-img {
  margin-left: 2rem;
  cursor: pointer;
}

.notification-title,
.notification-message {
  color: #434343;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.toast-flex {
  display: flex;
  margin-left: 1rem;
}

.toast-img {
  width: 1.5rem;
}

.document-add-date {
  border-bottom: 1px solid #e1e1e1 !important;
  border-top: unset !important;
  border-left: unset !important;
  border-radius: 0 !important;
  padding-left: 0;
}

.new_pad-invite {
  padding-bottom: 1.4rem !important;
}

.doc-add-group {
  border-bottom: 1px solid #e1e1e1 !important;
  border-top: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
  background-color: unset !important;
}

.tertiary-circle {
  color: #c2c2c2;
  font-size: 17px !important;
}

.radio-document-flex {
  display: flex;
  width: 47%;
  justify-content: space-between;
}

.input-document-flex {
  display: flex;
  align-items: center;
}

.yes-no-toggle {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.mail_template-card {
  padding-top: 1.8rem !important;
  padding-bottom: 4.2rem !important;
  padding-left: 4.3rem !important;
}

.mail-temp-back {
  background-color: unset !important;
}

.remove-form {
  cursor: pointer;
  width: 1.3rem;
}

.task_name {
  color: $secondayColor;
}

.form-fields {
  cursor: move;
  width: 70%;
  position: absolute;
  border: 1px solid #00000024;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  color: $tertiaryColor;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  z-index: 999;
}

.temp-card {
  width: 26%;
  height: 55%;
}

.flexing-template {
  display: flex;
  justify-content: space-between;
}

.new-form {
  border: 1px solid #00000024;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  color: $tertiaryColor;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  background: #75757526;
}

.template-container {
  height: 792px;
  width: 840px;
  z-index: 2;
}

.row-drag .marginTop-drag:nth-child(2),
.row-drag .marginTop-drag:nth-child(3) {
  margin-top: 3.5rem;
}

.modal-preview {
  max-width: 910px !important;
}

.mr-doc {
  margin-right: 1.4rem !important;
}

.object-fit {
  object-fit: contain;
}

.mgrpdf__wrapper {
  position: relative;
}

.mgrpdf__wrapper canvas {
  width: 840px !important;
  height: 900px;
}

.add-pdf {
  border: 1px dashed #c4c4c4;
  border-radius: 10px;
  width: 7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.7rem;
  position: relative;
  cursor: pointer;
}

.pdf-add-img {
  width: 2rem;
}

.c4-add {
  color: #c4c4c4;
  font-size: 15px;
}

.pdf-add-input {
  cursor: pointer !important;
}

.shadow,
.card-profile-image img {
  box-shadow: none !important;
}

.steps_2_pur {
  left: 99.3% !important;
}

.progress-purchase {
  width: 25% !important;
}

.purchase_two {
  left: 99.3% !important;
}

.p_3 {
  width: 99.3% !important;
}

// Toggle SideBar

.toggle-sidebar-width {
  position: absolute;
  height: 100vh;
  right: 0;
  top: 0;
  transition: 0.6s all;
  z-index: 99;
}

.toggle-sidebar {
  width: 0;
  position: absolute !important;
  height: 100vh;
  right: -60px;
  top: 0;
  transition: 0.5s all;
  z-index: 999;
}

.opac-div-initial {
  width: unset;
  opacity: 0;
  background-color: transparent;
}

.opac-div {
  position: fixed;
  left: 0;
  height: 100%;
  width: 100vw;
  opacity: 0.6;
  top: 0;
  z-index: 99;
  background-color: #000000;
}

.toggle-sidebar-width {
  width: 25vw;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 0;
  height: 100% !important;
  position: fixed !important;
}

.sec-btn-wrapping {
  background-color: transparent !important;
  color: $primaryColor;
  font-weight: 600;
}

.submit-side-tab {
  margin: unset !important;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: flex-end;
  width: 100%;
  margin-top: 70px !important;
}

.sec-btn-wrapping:hover {
  background-color: transparent !important;
  color: $primaryColor;
  font-weight: 600;
}

.display-content {
  display: block;
  height: 100%;
}

.maintain-canvas {
  background-color: #f5f5f5;
}

// new host design
.big_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-vertical {
  width: 16% !important;
  z-index: 9 !important;
}

.main-content {
  margin-left: 0px !important;
  width: 84%;
  transition: all 0.5s;
}

.main_content_login {
  width: 100%;
  transition: all 0.5s;
  margin-left: 0px !important;
}

.main-content .navbar-top {
  right: 0;
}

.vertical.navbar-expand-md.fixed-left+.main-content {
  transition: 0.5s all;
}

.container_fixed_div {
  // width: 6vw !important;
  position: fixed !important;
  left: 0px;
  top: 0px;
  width: inherit;
  overflow: visible !important;
  height: 100%;
  padding-bottom: 50px;
}

.container_fixed_div::-webkit-scrollbar {
  display: none;
}

.genaral_div_padding {
  padding: 0px 1.8rem;
}

.breadcrumb {
  font-size: 15px;
  color: #7b7b7b;
  // font-size: 15px !important;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.breadcrumb-item.active {
  color: #7b7b7b;
}

.card_with_paginator {
  border-radius: 0px;
  box-shadow: none !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 5px 5px 0px 0px !important;
  border-bottom: none !important;
}

.no_data_card {
  border: 1px solid #f5f5f5 !important;
  box-shadow: none !important;
}

.paginator_box {
  // border: 1px solid rgba(225, 225, 225, 0.4);
  // border-radius: 0px 0px 5px 5px;
  // display: flex;
  justify-content: flex-end;
  padding: 16px 10px;
  margin-top: 0px;
  border-top: none;
}

.page_available {
  color: #72569c;
  margin: 0px;
  font-weight: 500;
  font-size: 0.875rem;
}

.page_unavailable {
  color: #c4c4c4 !important;
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 500;
}

.image_upload_display {
  width: 90px;
  height: 90px;
  position: relative;
}

.date_para_info {
  background: #cdcdcd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  padding: 2px;
  cursor: pointer;
  margin-left: 10px;

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }

  i {
    font-size: 10px;
  }
}

.date_info {
  display: flex !important;
}

.table-responsive {
  overflow-x: auto !important;
}

.Calender_Events {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.events_details {
  width: 100%;
  // border: thin solid #f2f5f6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.event_container {
  width: 100%;
  height: 100%;
}

.calender_display {
  width: 70%;
  margin-right: 16px;
}

.calender_selectedmonth {
  display: flex;
  align-items: center;
  justify-content: center;
}

.current_month_display {
  margin: 0 40px;
  font-size: 18px;
  color: #303030;
  cursor: pointer;
}

.direction_month_button {
  cursor: pointer;
}

.calender_title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background: #fafafa;
}

.monthsDisplay {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5px;
  background: #f2f5f6;
  border: thin solid #f2f5f6;
}

.individual_month {
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100px;
}

.weekDays_display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1.5px;
  background: #fafafa !important;
  border: 0.5px solid #e1e8f0;
  border-left: none;
  border-right: none;
}

.individual_day {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background: #fafafa !important;
  height: 70px;
  align-items: center;
}

.days_display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1.5px;
  background: #f2f5f6;
  border: thin solid #f2f5f6;
}

.individual_days {
  display: flex;
  justify-content: flex-end;
  background: white;
  padding: 20px;
  cursor: pointer;
  height: 100px;
}

.marker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 10px 7px 10px 18px;
  cursor: pointer;
  height: 100%;
  min-height: 150px;
  min-width: 100px;

  .date {
    margin-bottom: 10px;
    padding: 10px 0px 0px 5px;
  }

  .eventList {
    // align-items: center;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // color: #333333;
    // font-size: 12px;
    // margin-bottom: 10px;
    width: 100%;
  }

  .eventDetail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px 5px 5px;
  }

  img {
    margin-right: 5px;
  }

  .eventname {
    line-height: 1 !important;
    color: #333333 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    overflow: hidden;
    width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .more {
    padding: 5px 7px 5px 5px;
  }
}

.blurback {
  opacity: 0.1;
}

.calenderSpin {
  position: absolute;
}

.event_title {
  background: #fafafa;
  height: 70px;
  display: flex;
  padding: 0px 24px;
  align-items: center;
}

.event_description {
  padding: 5px 24px;
  border-bottom: thin solid #f4f4f4;
}

.event_description:last-child {
  border-bottom: 0px;
}

.event_description_details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.event_head {
  margin: 10px 0px 20px 0px;
  color: #000000 !important;
}

.googevent:last-child {
  border-bottom: 0px;
}

.googevent {
  padding: 15px 24px;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  border-bottom: thin solid #f4f4f4;
}

.not_available {
  display: flex;
  justify-content: center;
  // align-items: center;
  padding: 10px 0px;
  min-height: 100px;
}

.statusSelect {
  border: 1px solid rgba(225, 225, 225, 0.4);
  background: none;
  width: 200px;
  font-size: 13px;
  color: #374957;
  padding: 0px 10px;
}

.statusSelect option {
  border: thin solid #374937;
}

.statusSelectOption:hover {
  background-color: #fff;
}

.calenderIcon {
  // width: 35% !important;
  margin-left: 20px;
}

.goog-te-gadget .goog-te-combo {
  // display: none;
  border: 1px solid rgba(225, 225, 225, 0.4) !important;
  border-radius: 5px !important;
  padding: 0px 5px 0px 30px;
  height: 40px;
  width: 160px;
  color: #374957;
  cursor: pointer;
}

.googDropDown {
  position: relative !important;
}

.translateIcon {
  position: absolute;

  margin-right: 10px;
  position: absolute;
  left: 18%;
  top: 50%;
  height: 35% !important;
  width: 20% !important;
  margin-right: 10px;
  transform: translate(-50%, -50%);
}

.form-control:disabled {
  background: none !important;
}

.google_translate .btn-secondary {
  background: #fff;
  width: 100%;
  border: 1px solid rgba(225, 225, 225, 0.4);
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.point {
  cursor: pointer !important;
}

.hori_timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-7p {
  margin-bottom: 7px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-4r {
  margin-top: 4rem;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-13 {
  margin-top: 13px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.w-300 {
  width: 340px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.w30 {
  width: 30%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.cl787878 {
  color: #787878;
}

.linkDiv {
  color: #787878;
}

.linkDiv:hover {
  color: #787878;
}

.scheduleDate {
  height: 46px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid rgba(200, 199, 199, 0.4);
  color: #374957;
  border-radius: 5px;
  margin-bottom: 20px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cancelbtn:not(:disabled):not(.disabled):active {
  color: $primaryColor !important;
}

.checkoutSubmit {
  // background-color: #0a8080;
  color: #ffffff;
  padding: 10px 30px;
}

.verticalUnset {
  vertical-align: unset !important;
}

.mr-2r {
  margin-right: 2rem;
}

.back-unset1 {
  background-color: unset !important;
  height: 50px;
  border: 1px solid #f0f0f0 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: none !important;
}

.mr-2r {
  margin-right: 2rem !important;
}

.closeModal {
  border-radius: 4px;
  background-color: #f6f6f6;
  padding: 3px 10px;
}

.f16 {
  font-size: 16px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.checkOutDatePick {
  height: 50px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #f0f0f0;
  color: #374957;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
  // border: transparent !important;
}

.verticalUnset {
  vertical-align: unset !important;
}

.cl262626 {
  color: #262626;
}

.cl555555 {
  color: #555555;
}

.cl000000 {
  color: #000000 !important;
}

.cl0A8080 {
  color: #0a8080 !important;
}

.cladb5bd {
  color: #adb5bd;
}

.profilePicName {
  // background-color: #fbfafa;
  // padding: 10px 20px;
  border-radius: 5px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pad25 {
  padding: 25px;
}

.pad092 {
  padding: 0.9rem 1rem 1rem 1.2rem;
}

.bortrans {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.clABABAB {
  color: #ababab;
}

.lftbtmfixed {
  position: absolute;
  left: 32px;
  bottom: 50px;
}

.postionRelative {
  position: relative;
}

.pad32 {
  padding: 20px 32px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-30 {
  margin-left: 30px;
}

.sideBarFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.w100 {
  width: 100% !important;
}

.h100 {
  height: 100% !important;
}

.h50p {
  height: 50px !important;
}

.disFl {
  display: flex;
  align-items: center;
}

.horiOverflow {
  overflow-x: scroll;
}

.horiOverflow::-webkit-scrollbar {
  display: none;
}

.payoutSideWidth {
  width: 600px;
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 0;
  right: 0;
  top: 0;
  transition: 0.6s all;
  z-index: 99;
  height: 100% !important;
  position: fixed !important;
  overflow: scroll;
}

.pad30p {
  padding: 30px !important;
}

.gridTwoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.gridThreeColumns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grGap30 {
  grid-gap: 30px;
}

.grGap20 {
  grid-gap: 0px 20px;
}

.cl757575 {
  color: #757575;
}

.cl72569C {
  color: #72569c;
}

.uploadFile {
  font-size: 13px;
  font-weight: 600;
  margin-top: 10px;
  color: #72569c;
}

.bbNone {
  border-left: none !important;
  border-right: none !important;
}

.cl303030 {
  color: #303030;
}

.cl333333 {
  color: #333333;
}

.cl939393 {
  color: #939393;
}

.cl00000080 {
  color: #00000080;
}

.cl212121 {
  color: #212121 !important;
}

.cl202020 {
  color: #202020 !important;
}

.cl22212F {
  color: #22212f !important;
}

.cl858585 {
  color: #858585 !important;
}

.ff-pop {
  font-family: "Poppins" !important;
}

.unsetAlign {
  align-items: unset !important;
}

.w40 {
  width: 40%;
}

.w30 {
  width: 30%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.mb-20 {
  margin-bottom: 20px;
}

.gridAuto {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  grid-gap: 12px;
}

.gridAuto65 {
  display: grid;
  grid-template-columns: 70px repeat(auto-fill, minmax(65px, 1fr));
  grid-gap: 12px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pad24p {
  padding: 24px !important;
}

.cl374957 {
  color: #374957;
}

.requestImageUploader {
  border: thin dashed #d6d6d6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 2px;
}

.imgFit {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.br-5 {
  border-radius: 5px;
}

.signup_dropDown {
  height: 47px !important;
  border: 1px solid #e1e1e1 !important;
}

.padl06 {
  padding-left: 0.65rem;
}

.geoLocation {
  position: relative;
}

.geoLocation ul {
  position: absolute;
  list-style: none;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #eee;
  min-height: 100px;
  max-height: 180px;
  overflow-y: scroll;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  width: 100%;
}

.geoLocation ul::-webkit-scrollbar {
  display: none;
}

.geoLocation li {
  padding: 5px 15px;
  border-bottom: 1px solid #00000014;
  color: rgba(0, 0, 0, 0.5);
}

.geoLocation ul li:hover {
  background: rgba(7, 126, 139, 0.1);
}

.eventTime {
  .react-datepicker__time-container {
    width: 100% !important;
  }

  .react-datepicker--time-only,
  .react-datepicker-popper {
    width: 100% !important;
  }

  .react-datepicker__time-box {
    width: 100% !important;
  }
}

.googleSync {
  div {
    display: none;
  }

  span {
    padding: 0.625rem 1.25rem !important;
    font-weight: 600 !important;
  }

  background-color: $primaryColor !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-family: "Poppins",
  "sans-serif" !important;
}

.googleSync:hover {
  background-color: $primaryColor !important;
  color: #fff !important;
  opacity: 1 !important;
}

.tagInputDiv {
  width: 100%;
  // height: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;

  // margin-bottom: 20px;
  input {
    border: 0px;
    font-size: 14px;
    padding-left: 4px;
  }

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px !important;

    padding: 0px !important;
  }

  li {
    background: $primaryColor;
    color: #fff;
    border-radius: 4px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    // margin-bottom: 10px;
  }

  span {
    margin-right: 10px;
  }

  img {
    background: #fff;
    border-radius: 25px;
  }
}

.error {
  border: 1px solid red !important;
}

.tagInputDivList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: 20px;

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px !important;

    padding: 0px !important;
  }

  li {
    background: $primaryColor;
    color: #fff;
    border-radius: 7px;
    padding: 5px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.tagInputAssinessList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0px !important;
  padding: 0px !important;

  li {
    position: relative;
    background: #f0f0f0;
    padding: 6px 10px;
    margin-right: 35px;
    margin-bottom: 10px;
  }

  li:after {
    content: "";
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    top: 0px;
    right: -26px;
    height: 100%;
  }
}

.calenderFilter {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 5px 13px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.exportSideBar {
  width: var(--width);
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 0;
  right: 0;
  top: 0;
  transition: 0.6s all;
  z-index: 99;
  height: 100% !important;
  position: fixed !important;
  overflow: scroll;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.eventSideBar {
  width: 450px;
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 0;
  right: 0;
  top: 0;
  transition: 0.6s all;
  z-index: 90;
  height: 100% !important;
  position: fixed !important;
  overflow: scroll;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.eventSideBar::-webkit-scrollbar {
  display: none;
}

.exportSideBar::-webkit-scrollbar {
  display: none;
}

.mark_payment_date {
  height: 46px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #e6e6e6;
  // border-right: none;
  border-radius: 10px;
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;
  color: #374957;
}

.dateIcon {
  position: absolute;
  right: 10px;
  width: 16px;
  top: 16px;
}

.eventTime {
  .react-datepicker__time-container {
    width: 100% !important;
  }

  .react-datepicker--time-only,
  .react-datepicker-popper {
    width: 100% !important;
  }

  .react-datepicker__time-box {
    width: 100% !important;
  }
}

.subNav {
  // margin-left: 2rem;
  white-space: nowrap;
}

.subNav:hover {
  color: $primaryColor !important;
}

.subChildActive {
  background-color: #ffffff;
  // border-top-left-radius: 4px;
  // border-bottom-left-radius: 4px;
  // border-top-right-radius: 2px;
  // border-bottoms-right-radius: 2px;
  border-right: none !important;
  color: #2e1a47 !important;
  height: 40px !important;
}

.subChildInActive {
  height: 40px !important;
}

.subChildInActive:hover {
  background-color: #3c235b;
}

// .active_parent {
//   position: absolute;
//   left: 0px;
//   top: 0px;
//   width: 5px;
//   background-color: white;
//   height: 100%;
//   border-top-right-radius: 4px;
//   border-bottom-right-radius: 4px;
// }

.active_parent_side_sub_menu {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 4px;
  background-color: #dcdbdb;
  height: 85%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.subChildActive::after {
  content: "";
  // position: absolute;
  // right: 0px;
  // top: 0px;
  // width: 5px;
  // background-color: white;
  // height: 100%;
  // border-top-left-radius: 4px;
  // border-bottom-left-radius: 4px;
}

.flexmid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pad10 {
  padding: 1rem 0rem;
}

.pad_sideBar_collapse {
  padding: 1rem 1.2rem;
}

.active_collapse_sidebar {
  background-color: white;
}

.sideNavContainer:hover .side-sub-menu {
  display: block;
  position: absolute;
  background: #2e1a47;
  z-index: 10;
  transform: translate(10%, 8%);
  left: 73%;
  top: -20%;
  border-radius: 0px 5px 5px 0px;
  // box-shadow: 1px 1px 5px 0.3px #dbdbdb !important;
  min-width: 170px;
}

.side-sub-menu {
  display: none;
  // display: block;
  // position: absolute;
  // background: #2E1A47;
  // z-index: 10;
  // transform: translate(10%, 8%);
  // left: 51px;
  // top: -10px;
  // border-radius: 0px 5px 5px 0px;
  // // box-shadow: 1px 1px 5px 0.3px #dbdbdb !important;
  // width: 130px;
}

.side-sub-menu .nav-link {
  display: flex !important;
}

.side-sub-menu .nav-link:hover {
  background-color: #3c235b;
}

.side_popup {
  background-color: #000000;
  // display: none;
  position: absolute;
  right: -11px;
  display: block;
}

.active_collapse_sidebar:hover .side_popup {
  position: absolute;
  right: -11px;
  display: block;
}

.pad0p {
  padding: 0px !important;
}

.inviteModal {
  max-width: 500px !important;
}

.btn-info-inactive {
  color: #757575 !important;
  background-color: #f3f3f3 !important;
  border: none !important;
  margin-left: 0px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  box-shadow: 1px 1px 2px 0.3px #dbdbdb !important;
}

.btn-info-inactive:hover {
  background-color: #f3f3f3 !important;
}

.googleEventList:hover {
  background: rgba(10, 128, 128, 0.1) !important;
  border-radius: 4px !important;
}

.h35 {
  height: 35px !important;
}

.invoiceStatusView .status_select_div {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-radius: 5px;
  background: white !important;
  color: #374957;
  font-size: 0.813rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 1px;

  .btn-secondary {
    color: #374957;
    font-size: 0.813rem !important;
    font-weight: 400 !important;
    border: none;
  }

  .btn-secondary:hover,
  .btn-secondary:active {
    background: white !important;
    box-shadow: none;
    // border-top: 1px solid rgba(225, 225, 225, 0.4);
  }

  .btn-secondary:focus {
    box-shadow: none !important;
  }

  .capitalize {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.dashMonthFilter {
  width: 11%;
}

.addressLoc {
  position: relative;
  width: 50%;

  input {
    height: 50px !important;
  }
}

.reqLoc {
  position: absolute;
  right: 20px;
  top: 18px;
}

.propList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.individual_prop {
  border: 1px solid #f6f6f6;
  border-radius: 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  padding: 10px 25px 10px 10px;
}

.prop_selected {
  border: 1px solid #0a8080 !important;
  background: rgba(10, 128, 128, 0.05);
}

.noneProp {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 20px;
  color: #202020;
  font-size: 14px;
  margin-bottom: 20px;
}

.issueDetails {
  .issueInput {
    height: 50px;
    border: 1px solid #f3f3f3;
  }

  label {
    color: #2a1e17;
    font-weight: 400;
    font-size: 14px;
  }

  .issue-description {
    width: 65%;
    height: 90px;
    border: 1px solid #f3f3f3;
  }
}

.issueConfirmDetails {
  .issuedetail {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .issueInput {
    height: 50px;
    border: 1px solid #f3f3f3;
  }

  label {
    color: #2a1e17;
    font-weight: 400;
    font-size: 14px;
  }

  .issue-description {
    width: 65.5%;
    height: 90px;
    border: 1px solid #f3f3f3;
  }
}

.invoiceTimeline:last-child {
  .timeLineVerLine {
    border-left: 0px;
  }
}

.flexMidColumn {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
}

.timeLineRound {
  background-color: #d3d3d3;
  border-radius: 25px;
  width: 8px;
  height: 8px;
}

.timeLineVerLine {
  border-left: 1px solid #dad9d9;
  height: 100%;
}

.timelineReason {
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.align_midd {
  text-align: center !important;
}

.lh-12 {
  line-height: 12px !important;
}

.otpBox {
  min-width: 12px;
  min-height: 20px;
  border: 1px solid black;
}

.otpInput {
  border: transparent;
  width: 12px;
  height: 40px;
}

.bnone {
  border: none !important;
}

.op5 {
  opacity: 0.5 !important;
}

.individual_day_week {
  padding: 10px 20px;
  background: #fff;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dayEvent {
  background-color: #2e1a47;
  padding: 12px;
  color: #fff;
  font-weight: 500;
  width: 200px;
  z-index: 1;
  min-height: 60px;
  border: 1px solid #fff;
}

.daysDisplay {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 31px 25px;
  height: 600px;
  overflow: scroll;
  position: relative;
}

.daysDisplay::-webkit-scrollbar {
  display: none;
}

.add_eclipse {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}

.dayLine {
  position: relative;
  border: none;
  border-bottom: 1px solid #eaeaea;
  width: 95%;
}

.h120 {
  height: 120px;
}

.pad20 {
  padding: 20px !important;
}

.columnFlexCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timelineData {
  position: absolute;
  top: 20px;
  left: -85px;
}

.w300 {
  width: 300px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.Btn_align {
  margin-top: 20px;
  display: flex;
  align-items: end;
}

.spinner_size {
  height: 20px;
  width: 20px;
}

.request_complete_stages_div {
  padding: 0px;
}

.radio_button {
  width: 20px;
  height: 20px;
  accent-color: #258080;
}

.assignFrstImg {
  display: flex;
  margin: auto;
  margin-bottom: 45px;
}

.info_icon {
  margin-left: 10px;
  font-size: 19px;
  color: gray;
}

.buttons_sytle {
  display: flex !important;
  justify-content: end !important;
}

.req_side_slide {
  width: 24vw !important;
}

.sidebar_width {
  width: 70vh;
  border-radius: 5px;
}

.card_fullbody {
  background-color: #ffffff;
  width: 100%;
  height: 108px;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}

.cards {
  width: 25%;
  height: 75px;
  align-items: center;
  padding: 20px 20px 20px 30px;
  margin: auto;
  border-right: 1px solid #f8f8f8;
}

.card_img {
  width: 40px;
}

.card_head {
  font-size: 12px;
  color: #757575;
  margin-top: 13px;
}

.card_counts {
  font-size: 22px;
  font-weight: 600;
  color: #374957;
}

.cards_content {
  margin-left: 19px;
}

.card_flex {
  width: 100%;
  display: flex;
  align-items: center;
}

.left_dashboard {
  // width: 97.75%;
  display: flex;
  row-gap: 30px;
  flex-direction: column;
}

.right_dashboard {
  // width: 24%;
}

.share_profile {
  padding: 25px;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  height: 400px;
}

.dashboard_div {
  // display: flex;
  // width: 100%;
  // gap:30px;
  // justify-content: space-between;

  display: grid;
  grid-template-columns: 75% 23%;
  grid-column-gap: 1.8%;
}

.profile_review {
  display: flex;
  justify-content: space-around;
  margin-top: 34px;
  align-items: center;
}

.bd_rgt {
  border-right: 1px solid #edebeb;
  width: 30%;
  height: 56px;
}

.bd_rgt_last {
  width: 30%;
  height: 56px;
}

.pfl_share_btn {
  background-color: #0a8080 !important;
  color: white;
  border-radius: 5px;
  margin-top: 24px;
  border: none;
}

.profile_btn {
  width: 122px;
  height: 40px;
  font-size: 13px;
  margin-top: 35 px;
}

.invite_btn {
  width: 128px;
  height: 40px;
  font-size: 14px;
}

.image_pxl {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.graph_left {
  width: 100%;
}

.invite_payout_feild {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.invite_feild {
  height: 535px;
  width: 48.5%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}

.invite_feild_h1 {
  font-size: 21px;
  font-weight: 600;
  line-height: 20px;
  margin: 9px 0px 20px 0px;
}

.invite_p {
  font-size: 14px;
  color: #575757;
  font-weight: 400;
  line-height: 24px;
}

.payout_feild {
  // height: 100%;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.0392156863);
  border: 1px solid #e1e8f0;
  border-radius: 10px;
  overflow-y: scroll;
  height: 535px;
  width: 48.5%;
  background-color: #ffffff;
  text-align: center;
  // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.payout_feild::-webkit-scrollbar {
  display: none;
}

.upcoming_appointment {
  width: 100%;
  height: 718px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.0392156863);
  border: 1px solid #e1e8f0;
}

.upcmg_head {
  background-color: #72569c;
  border-radius: 10px 10px 0px 0px;
  padding: 15px;
  width: 100%;
  // height: 45px;
  color: white;
  align-items: center;
}

.wd-5 {
  width: 5px;
}

.dash_cal_individual_day {
  display: grid;
  grid-gap: 1.5px;
  color: #555555;
  font-weight: 500;
  font-size: 12px;
  background-color: #fafafa;
  /* border: thin solid #f2f5f6; */
  justify-content: center;
  padding: 10px;
  text-align: center;
}

.every_date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  cursor: pointer;
  height: 100%;
  align-items: center;
  font-size: 12px;

  .date {
    margin-bottom: 10px;
    padding: 10px 0px 0px 5px;
  }
}

.each_days_display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background: #ffffff;
}

.eventList_th {
  font-size: 12px;
  font-weight: 500;
  color: #111111;
}

.eventList_div {
  border-bottom: 1px solid #f8f8f8;
  padding: 20px;
}

.payout_header {
  background-color: #fafafa !important;
  border: 0.5px solid #e1e8f0;
  border-left: none;
  border-right: none;
  border-color: #e1e8f0;

  tr {
    color: #555555;
    font-size: 13px;
    font-weight: 500;

    th {
      padding: 18px 26px;
      font-weight: 400;
      color: #212121;
      width: 37%;
    }
  }
}

.shareProfilePicName {
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 12px;
}

.Cl012A4D {
  color: #012a4d;
}

.verify {
  position: absolute;
  width: 30px;
  left: 52%;
  transform: translate(50%, 50%);
  top: 49%;
}

.dates {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #72569c;
  color: white;
  margin-bottom: 10px;
  // padding: 4px 0px 2px 8px;
  min-width: 50%;
  padding: 4px;
  border-radius: 50%;
  margin: auto;
}

.view_all {
  margin-right: 27px;
  border: none !important;
}

.refer_left_poppop {
  justify-content: center;
  height: 100%;
  display: flex;
}

.refer_boxes {
  border: 1px solid #bcd2e6;
  padding: 26px;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  cursor: pointer;
  // transition: 1s;
}

.refer_boxes:hover {
  // transform: scale(1.05);
}

.scroll_design {
  &::-webkit-scrollbar {
    height: 6px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }

  scrollbar-width: thin;
}

// .refer_boxes:hover {
//   // transform: scale(1.05);
// }
.refer_boxes_active {
  border: 1px solid #012a4d;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.refer_input {
  width: 80%;
  border: 1px solid #d9d9d9;
  border-radius: 5px 0px 0px 5px;
  height: 42px;
  border-right: none;
  color: #000000;
}

.refer_input_copy {
  border: 1px solid #d9d9d9;
  width: 20%;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  justify-content: space-around;
  height: 42px;
  display: flex;
  justify-content: center;
  margin-right: 3px;
  cursor: pointer;
}

.clffffff {
  color: #ffffff;
}

.suggestion {
  margin-top: 12px;
}

.otpContainer {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 35px;
}

.otpInput {
  width: 100%;
  border: none;
  border-bottom: 0.6px solid #d9d9d9;
  margin-bottom: 30px;
  letter-spacing: 0px;
  text-align: center;
}

.cl525f7f {
  color: #525f7f;
}

.position_u {
  position: unset;
}

.mobRespFeild {
  margin: auto;
  padding: 50px;
  width: 430px;
  height: 935px;
  background-color: white;
  position: relative;
}

.otpResendContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mobileRespContent {
  justify-self: center;
  text-align: center;
}

.mobileRespContent h2 {
  font-size: 30px;
  font-weight: 600;
  margin-top: 50px;
}

.mobileRespContent p {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  align-items: center;
  margin-top: 20px;
}

.MobButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 75%;
  margin: auto;
  margin-top: 10px;
}

.MobButtons img {
  margin-right: 10px;
}

.iphoneBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 5px;
  border: none;
  background: #077e8b;
  color: white;
  margin-bottom: 13px;
  width: 249px;
  height: 50px;
  margin-top: 60px;
}

.playstoreBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 5px;
  border: none;
  color: black;
  margin-bottom: 13px;
  background-color: #dfdee1;
  width: 249px;
  height: 50px;
}

.mobileAppSuggestion {
  display: none;
}

@media (max-width: 450px) {
  .mobileAppSuggestion {
    display: block !important;
  }

  .mainRouter {
    display: none;
  }
}

.enableGreen {
  color: green !important;
}

.enableRed {
  color: red !important;
}

.mobRespIcon {
  display: flex !important;
  justify-content: space-between;
}

.mobRespLogo {
  top: 3.5%;
  left: 82%;
  width: 27%;
  transform: translate(-50%, -50%);
}

.comment_Bodering {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
}

.wordBreakAll {
  word-break: break-all;
}

.overflow {
  overflow: auto !important;
}

.chat_hero {
  height: calc(100vh - 180px);
  overflow: auto;

  &.noChats {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }
}

.chatContainer {
  text-align: center;
  padding: 4px 15px;

  .timeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6c6b6b;
    margin: 20px 0px;

    & div:nth-child(1) {
      padding: 10px 15px;
      background-color: #faf8f8;
      border-radius: 5px;
    }
  }

  .chat_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .serviceProvider_cotainer {
    width: 80%;
    text-align: start;
    // margin-top: 20px;
  }

  .serviceMessage {
    // position: relative;
    padding: 10px;
    background-color: #f1f7ff;
    border-radius: 0px 5px 5px 5px;

    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    & .replyName {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #2201ea;
    }

    &.replyContent {
      color: "#ffffff";
    }

    .time {
      // position: absolute;
      // right: 0;
      // bottom:1px;
      text-align: end;
      margin-left: 10px;
      font-size: 12px;
      min-width: 50px;
      margin-top: 3px;
    }
  }

  .answer_container {
    text-align: start;
    color: #ffffff;
    margin-left: 20%;
    display: flex;
    justify-content: flex-end;
  }

  .myMessage {
    // position: relative;
    padding: 10px;
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    background-color: #2e1a47;
    border-radius: 5px 0px 5px 5px;

    &:nth-child(1) {
      white-space: pre-wrap;
    }

    .time {
      // position: absolute;
      // right: 0;
      // bottom:1px;
      text-align: end;
      margin-left: 10px;
      margin-top: 5px;
      font-size: 12px;
      min-width: 50px;
    }
  }

  // & div:nth-child(2) {
  //   display: flex;
  //   width: 80%;

  //   text-align: start;
  //   margin-top: 20px;
  //   padding: 15px;
  //   background-color: #F1F7FF;
  //   border-radius: 0px 5px 5px 5px;

  //   // .time {
  //   //   display: table;
  //   //   margin-left: auto;
  //   // }
  // }
}

.chat_footer {
  padding: 20px 20px 20px 20px;

  .ft_1 {
    // position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    min-height: 50px;
    padding: 15px;
    background-color: #fafafa;
    border-radius: 8px;

    & textarea {
      width: 90%;
      // border: none;
      border-color: transparent;
      background-color: #fafafa;
      resize: none;
      overflow: hidden;
    }

    & .ft_img {
      // position: absolute;
      // top: 10px;
      // right: 10px;

      & img {
        cursor: pointer;
      }
    }
  }
}

.proj_heading {
  color: #333;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.desc_heading {
  color: #010101;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 100% */
}

.desc_sub_text {
  color: #757575;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.project_detail_data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project_detail_client {
  color: #757575;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 100% */
}

.project_detail_value {
  // color: #010101;

  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  // font-weight: 500;
  line-height: 13px;
  /* 100% */
}

.status_change_value {
  color: #ffbc00;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 100% */
}

.drawer_desc {
  cursor: text;
  display: flex;
  gap: 10px;
  color: #757575;
  flex-wrap: wrap;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}

.drawer_sub_heading {
  color: #333;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  // font-weight: 500;
  line-height: 13px;
  /* 100% */
}

.back-unset1 {
  height: 41px;
}

.checkOutDatePick {
  height: 41px;
}

.ant-drawer .ant-drawer-header {
  background-color: #f2f2f2 !important;
}

.ant-picker.ant-picker-disabled {
  background-color: transparent !important;
}

.project_list_percentage {
  color: #606060;

  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  /* 100% */
}

.table td .progress {
  height: 5px;
  width: 85px;
  color: #e3eded !important;
}

.table td .progress .progress-bar {
  background-color: #2e1a47 !important;
}

.project_list_status {
  color: #ffcc3d;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  line-height: 14px;
  /* 100% */
}

.card .table td .br_none {
  border-right: none !important;
}

.card .table td .bl_none {
  border-left: none !important;
}

.project_list_content {
  // margin: 1rem 0;
  color: #212121;
  width: 100%;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  // overflow-y: scroll;
  /* 100% */

  input {
    padding: 0px;
  }
}

.project_list_content::-webkit-scrollbar {
  display: none;
}

.project_list_content .item_description {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  img {
    cursor: pointer;
  }
}

.br_none {
  border-right: none !important;
}

.bl_none {
  border-left: none !important;
}

.project_card_number {
  color: #010101;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
  margin-bottom: 6% !important;
}

.project_card_sub {
  color: #757575;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 100% */
}

.border_project {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 5px;
}

.ant-upload.ant-upload-select {
  width: 100% !important;
}

.form_select.fixed_select {
  cursor: pointer !important;
}

.task_calender_date {
  width: 35px;
  height: 35px;
  // padding-left: 6.5%;
  padding-top: 3%;
  border-radius: 50%;
  color: black;
  text-align: center;
  // background-color: #0A8080;
}

.marker_task {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 10px 7px 10px 18px;
  cursor: pointer;
  height: 100%;
  min-height: 150px;
  min-width: 100px;
}

.cardContainer {
  padding: 20px;
}

.tagChip {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  background-color: #f5f5f5;
  align-items: center;
  border-radius: 3px;
  font-size: 14px;
  color: #212529;
  // height: 30px;
  // width: 350px;
  word-break: break-all;
  border-radius: 20px;
}

.tagInput {
  border: transparent;
  width: 400px;
}

.clientAddInAddProject {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .addClient {
    color: #338180;
    font-weight: 500;
    cursor: pointer;
  }

  .bordered-date {
    border-radius: 50%;
    background-color: #0a8080;
    color: white;
  }
}

.task_sub_drawer_title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #010101;
}

.task_drawer_desc {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
  word-break: break-all;
}

.task_drawer_data {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.vertical-line {
  border-left: 1px solid #f0f0f0;
  height: 1.8rem;
}

.custom-slider-container {
  display: flex;
  align-items: center;
}

/* Style the slider track */
.ReactSlider {
  width: 100%;
}

/* Style the dragger handle */
.ReactSlider-thumb {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 2px solid #2e1a47;
  border-radius: 50%;
  cursor: pointer;
}

/* Style the dragger label */
.dragger-custom {
  margin-top: 10px;
  font-size: 16px;
  color: #000;
}

.kanban-card-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  color: "#333333";
}

.task_dash_icon:hover {
  background-color: #f0f0f0 !important;
}

.progress-bar {
  background-color: #2e1a47 !important;
}

.bg_none:hover {
  background-color: white !important;
}

.bg_task_wrapper:hover {
  background-color: #f0f0f0 !important;
}

.border_trans {
  border: transparent;
}

.calenderViewDashboard {
  overflow: auto;
  height: 350px;

  &::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }
}

.kanBoardChart {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
}

// .projectListContainer {
//   margin: ;
// }\\
.overdueFiled {
  position: relative;
  border: 1px solid #e1e8f0;
  padding: 1rem;
  border-radius: 10px;
  border-top: 5px solid #0070fd;
  // min-width: 26.5rem;
  // min-height: 10rem;
}

.noDataPresent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #d0d0d0;
  border-radius: 3px;
  margin-top: 7px;
}

.timeline_container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.timeline_body {
  overflow: auto;
  // height: calc(100% - 50px);
  height: 900px;

  &::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }
}

.text-wrap {
  text-wrap: wrap;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #338180;
  border-color: #338180;
}

.bordered-table th {
  border: 1px solid #f0f0f0 !important;
}

.border-cells td {
  border: 1px solid #f0f0f0 !important;
}

.border-cells td.br_none {
  border-right: none !important;
}

.border-cells td.bl_none {
  border-left: none !important;
}

.crm-form-control {
  border: none !important;
}

.crm-form-control:focus {
  border: none !important;
}

.AcceptOrrejectContainer {
  width: 900px;
  background: #ffffff;
  margin: 30px auto;
  padding-bottom: 30px;

  .insideBox {
    overflow-y: auto;
    width: 100%;
    background: #ffffff;
    padding: 56px 40px 40px;
    box-shadow: 0 0 5px #00000040;

    .logoBox {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
    }

    .end_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }
  }

  .billing_details {
    padding: 30px 0 !important;
    border-bottom: 1px solid #f6f6f6;
    display: grid;
    grid-template-columns: 5fr 5fr;

    .right_top_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .no_address {
      height: 125px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;
      font-weight: 400;
      color: #f14c52;
    }
  }
}

.conCanMod {
  font-size: 20px;
  text-align: center;
  color: #212121;
  font-weight: 500;
  margin-bottom: 16px;
}

.twoContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2%;
  padding-bottom: 2%;
  min-height: 550px;
  margin-bottom: 2%;

  .top_container_hero {
    padding: 3%;
    display: flex;
    justify-content: space-between;

    .top_right {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }

  .left {
    border: 1px solid #dadce0;
    border-radius: 5px;
    width: 50%;
  }

  .right {
    border: 1px solid #dadce0;
    width: 50%;
    border-radius: 5px;

    .right_switchable {
      width: 100%;
      height: 100%;
      display: flex;
      flex: 3 1;
      // position: relative;

      // .right_right {
      //   border: 1px solid rgba(0, 0, 0, 0.05);
      //   position: absolute;
      //   height: 100%;
      //   width: 10%;
      // }
      .switchable_slides {
        padding: 2%;
        min-width: 93%;
      }

      .contents_listing {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid rgba(0, 0, 0, 0.05);

        .content_img {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 40% 0%;
        }
      }
    }
  }
}

.crm_comments {
  height: 35px;
  overflow: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: center;
  min-height: 360px;

  &.noChats {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }

  .crm_individual_comments {
    padding: 2% 1%;
    display: flex;
    gap: 3%;
    // align-items: center;

    .crm_comment_content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .crm_comment_date {
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #757575;
    }
  }

  .crm_individual_comments_module {
    padding: 1% 1%;
    display: flex;
    gap: 3%;
    // align-items: center;

    .crm_comment_content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .crm_comment_date {
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #757575;
    }
  }
}

.crm_comments_input {
  padding: 0% 1%;
  margin-top: 40px;
  gap: 3%;
  display: flex;
  align-items: center;

  .comment_footer {
    .ft_1 {
      padding: 0px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 50px;
      background-color: #fafafa;
      border-radius: 8px;
    }

    textarea {
      height: 30px;
      // padding: 0px 10px;
      width: 90%;
      border-color: transparent;
      background-color: #fafafa;
      resize: none;
      overflow: hidden;
    }
  }
}

.comment_footer {
  width: 100%;
}

.ptb20 {
  padding: 20px 0px !important;
}

.crm_uploadDocumentsComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  width: 100%;
  outline: 1px dashed #f0f0f0;
  row-gap: 3%;
  gap: 20px;
}

.upload_btn {
  padding: 5px 7px;
  min-height: 15px;
  // width: 100%;
  border: 1px solid rgb(196, 196, 196);
  cursor: pointer;
  color: #338180;
  border-radius: 5px;
}

.crm_comments_module {
  border: 1px solid #f0f0f0;
  height: 700px;
  overflow: auto;

  &.noChats {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }
}

.payments_top_header {
  .paymentTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #303030;
    margin-bottom: 1rem;
  }

  .paymentPara {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #757575;
    margin-bottom: 2rem;
    // width: 80%;
  }

  .payments_card {
    display: flex;
    align-items: center;
    gap: 2%;
    justify-content: space-between;

    .card {
      width: 100%;
      padding: 20px;

      .card_hero {
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(225, 225, 225, 0.4);

        .card_top_left {
          display: flex;
          flex-direction: column;

          .card_top_left_value {
            font-weight: 500;
            font-size: 20px;
            margin-top: 18px;
            color: #303030;
          }
        }
      }

      .card_footer {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;

        .img_background {
          padding: 5px;
          border-radius: 5px;
          background-color: #f6f9fe;
        }
      }
    }
  }
}

.proposalAmount {
  font-weight: 500;
  font-size: 15px;
}

.ant-switch-checked {
  background-color: #0a8080 !important;
}

.clientContainer {
  padding: 1% 2%;
  background-color: #f2f3f7;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .profileName {
    font-size: 16px;
    color: #757575;
    font-weight: 400;
    cursor: pointer;
  }
}

.clientBody {
  margin: 1.5% 2%;

  .projectLists {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    grid-column-gap: 24px;
    column-gap: 24px;
    grid-row-gap: 24px;
    row-gap: 24px;

    .ProjectCard {
      box-shadow: 0 0 4px #00000026;
      border-radius: 8px;
      cursor: pointer;

      .projectName {
        background: #fcfcfc;
        padding: 18px 24px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #75728f;
      }

      .project_bottom {
        padding: 18px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .project_left {
          .due_date {
            font-weight: 400;
            font-size: 13px;
            margin-bottom: 6px;
            color: #bbb;
            word-break: break-all;
          }
        }
      }
    }
  }

  .BodyHeaderName {
    font-size: 24px;
    font-weight: 500;
    color: #212529;
    margin-bottom: 2%;
  }

  .clientBody_body {
    margin-bottom: 2%;
  }
}

.title_projects {
  font-weight: 600;
  font-size: 18px;
  color: #333;
}

.split_up th {
  background-color: white !important;
}

.percentageModule {
  display: flex;
  align-items: center;
}

.percentageInputBar {
  width: 100px;
}

.percentage_select {
  position: relative;
  width: 15%;

  select {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(225, 225, 225, 0.4);
    border-radius: 5px;
    background: white !important;
    color: #374957;
    font-size: 0.813rem !important;
  }

  .percentage_select_div {
    width: 100%;
    height: 40px;
    // border: 1px solid rgba(225, 225, 225, 0.4);
    // border-left: 1px solid #F3F3F3;
    // border-top: 1px solid #F3F3F3;
    // border-bottom: 1px solid #F3F3F3;
    border: 1px solid #f3f3f3;
    border-radius: 0px 5px 5px 0px;
    background: white !important;
    color: #374957;
    font-size: 0.813rem !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 1px;

    .btn-secondary {
      color: #374957;
      font-size: 0.813rem !important;
      font-weight: 400 !important;
      border: none;
    }

    .btn-secondary:hover,
    .btn-secondary:active {
      background: white !important;
      box-shadow: none;
    }

    .btn-secondary:focus {
      box-shadow: none !important;
    }
  }

  .percentage_select_div:hover,
  percentage_select_div:active {
    box-shadow: none;
    background-color: #f7f8fa !important;
  }

  .drop_down_custom_div {
    position: absolute;
    height: fit-content;
    width: 100%;
    top: 100%;
    left: 0px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: 99;
    background: white;
    padding: 10px;
  }

  .dropdown_options {
    font-size: 14px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    margin: 0px;
    padding-top: 12px;
  }

  .dropdown_options:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  // i {
  //   position: absolute;
  //   right: 13px;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }
}

.percentageInputbar {
  padding: 0px 5px;
  width: 100px;
  height: 40px;
  border-radius: 0px 5px 5px 0px;
  background-color: #ffffff;
  //  border-left: 1px solid #F3F3F3;
  //  border-top: 1px solid #F3F3F3;
  //  border-bottom: 1px solid #F3F3F3;
  border: 1px solid #e6e6e6;
}

.emptySplitUp {
  padding: 30px 30px;
  text-align: center;

  .first {
    font-size: 16px;
    font-weight: 500;
  }

  .second {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
  }
}

.vertical-border {
  border-left: 1px solid #1c1c1c1a;
  height: 70px;
  margin: 0px 20px;
}

.project_detail_bar .ant-progress-inner {
  height: 2rem !important;
  border-radius: 8px !important;
}

.flex_col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.estimate_table td {
  border: 1px solid #f0f0f0;
}

.estimate_card_with_paginator td {
  border-bottom: 1px solid #f5f5f5;
}

.payment_box {
  max-width: 280px;
  margin-top: 15px;
}

.payment_box_head {
  background: #e1e8f0;
  padding: 16px;
  color: black;
  font-weight: 600;
  font-size: 15px;
  width: 249px;
  border-radius: 10px 10px 0px 0px;
}

.payment_box_body {
  background: #ffffff;
  padding: 12px 9px 16px 14px;
  color: black;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #e1e8f0;
  border-radius: 0px 0px 10px 10px;
}

.estimate_table_font {
  font-family: Poppins;
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
}

// CustomButtons ///////////////

.d_flex_sb_ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d_flex_sb {
  display: flex;
  justify-content: space-between;
}

.custom_pri_btn {
  padding: 10px 23px;
  font-size: 13px;
  background-color: #72569c;
  border: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  transition: 0.5s;
}

.custom_pri_btn:hover {
  background-color: #2e1a47;
}

.custom_sec_btn {
  padding: 6px;
  font-size: 13px;
  border: 1px solid #72569c;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  background-color: transparent;
  color: #72569c;
  transition: 0.5s;
}

.custom_sec_btn:hover {
  background-color: #72569c;
  color: white;
}



.custom_btn_no_border {
  // padding: 10px 0px 10px 23px;
  font-size: 13px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  color: #72569c;
  background-color: transparent;
}

.grid_project_overview {
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;

  .right_side {
    justify-self: end;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.cardBody {
  box-shadow: 0px 2px 20px 0px #0000000a;
  border: 1px solid #e1e8f0;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 24px 12px;
}

.single_card_big {
  border: 1px solid #e1e8f0;
  box-shadow: 0px 2px 20px 0px #0000000a;
  display: grid;
  grid-template-columns: 24.5% 25.5% 25.5% 24.5%;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 1.8rem;

  .single_card {
    padding: 16px 24px;
  }

  .single_card:not(:last-child) {
    border-right: 1px solid #e1e8f0;
  }

  .card_header {
    color: #757575;
    font-weight: 500;
    font-size: 13px;

    .head {
      display: flex;
      height: 100%;
      align-items: center;
      margin-bottom: 0.8rem;
    }

    .card_value {
      font-size: 24px;
      font-weight: 500;
      color: #757575;
      margin-bottom: 1rem;
    }

    .card_footer {
      font-size: 12px;
      font-weight: 300;
      color: #757575;
    }
  }
}

.customContainer {
  padding: 24px;
  border: 1px solid #e1e8f0;
  background-color: #ffffff;
  border-radius: 10px;
}

.customListingContainer {
  border: 1px solid #e1e8f0;
  background-color: #ffffff;
  border-radius: 10px;
}

.listingHeader {
  min-height: 60px;
  padding: 0px 24px;
  border-bottom: 0.5px solid #e1e8f0;
}

.containerListing_name {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

table {
  margin-bottom: 0px !important;
}

.custom_table {
  padding: 24px;
  background-color: white;
  border: 1px solid #e1e8f0;
  border-radius: 10px;
  // margin-bottom: 1.8rem;

  table {
    width: 100%;

    thead>tr {
      th {
        color: #555555;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
}

.customListContainer {
  padding: 24px;
  background-color: #fafafa;
}

.proposal_date .ant-picker-suffix {
  display: none !important;
}

.font_standard {
  font-size: 13px;

  line-height: 14px;
  color: #212121;
}

.fw_500 {
  font-weight: 500 !important;
}

.btn_secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #72569c;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid #72569c;
  gap: 10px;
}

.pdl-2 {
  padding-left: 1.5rem !important;
}

.handle_word_breaks {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 28%;
  min-width: 28%;
  text-align: justify;
  line-height: 2rem;
}

.bold_standard {
  font-size: 16px;

  line-height: 16px;

  color: #212121;
}

.border-bottom {
  border-bottom: 0.5px solid #e1e8f0 !important;
}

.sidebarWhite {
  color: #bdb6ca !important;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 10px;
}

.padForSidebar {
  padding: 0px 18px !important;
}

.navbar-vertical.navbar-expand-md .navbar-nav .sideNavBar_active {
  padding: 0px 10px !important;
  display: flex;
  justify-content: flex-start;
}

.navbar-vertical.navbar-expand-md .navbar-nav .sideNavBar_active:hover {
  // background-color: #3c2855;
  border-radius: 0px 5px 5px 0px;

  .sidebarWhite {
    color: white !important;
  }
}

.side_bar_small_logo {
  display: flex;
  justify-content: center;
}

.pad_none {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.table_font {
  font-size: 13px !important;
  font-weight: 500;
  color: #212121 !important;
}

.estimate_heading_font {
  font-size: 16px;
  font-weight: 500 !important;
  color: #303030 !important;
}

#splitup_table_header {
  font-size: 13px !important;
  font-weight: 500 !important;
  // color: #555555 !important;
  padding-left: 1.5rem !important;
}

.splitup_cell {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #212121 !important;
}

.preview_font_sub {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #212121 !important;
}

.fs_13 {
  font-size: 13px !important;
}

.estimate_preview {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #555555 !important;
}

.preview_heading_font {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #303030 !important;
}

.preview_subject {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #303030 !important;
}

.preview_font {
  font-size: 18px !important;
  font-weight: 500 !important;
}

#bor_right {
  border-right: none !important;
}

.lead_modal.modal-content {
  min-width: 681px !important;
  min-height: 361px !important;
}

#border_none {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #555555 !important;
  // padding-left: 1.5rem !important;
  border: none !important;
}

#border_bottom {
  border: 0 !important;
  border-right: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.drop_estimate {
  max-width: 3rem !important;
}

.table thead {
  background-color: #fafafa;
  border: 0.5px solid #e1e8f0;
  border-left: none;
  border-right: none;
}

.table thead th {
  color: #72569c !important;
  font-size: 13px;
  font-weight: 500;
}

.table tbody td {
  color: #212121;
  font-size: 13px;
  font-weight: 400;
}

.custom_drop_down_search {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  padding: 7px 5px;
  border-radius: 10px;

  &.custom_input {
    border: none;
    width: 96.2%;
    height: 50%;
    font-size: 14px;
    color: #212121;
  }

  &.custom_input::placeholder {
    color: #adb5bd;
    font-size: 14px;
  }

  &.onActive {
    border: 1px solid red;
  }
}

.input-container {
  position: relative;
  display: inline;

  .input_lists {
    position: absolute;
    width: 100%;
  }

  .input_lists ul {
    position: absolute;
    list-style: none;
    padding: 1rem;
    background-color: #fff;
    border: 1px solid #eee;
    min-height: 100px;
    max-height: 180px;
    overflow-y: scroll;
    padding: 0;
    cursor: pointer;
    z-index: 1;
    width: 100%;
  }

  ul::-webkit-scrollbar {
    display: none;
  }

  .input_lists li {
    padding: 5px 15px;
    border-bottom: 1px solid #00000014;
    color: rgba(0, 0, 0, 0.5);
  }

  .input_lists ul li:hover {
    background: rgba(7, 126, 139, 0.1);
  }
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust as needed */
  transform: translateY(-50%);
  width: 15px;
  /* Adjust as needed */
  height: 15px;
  height: auto;
}

#myInput {
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  height: 45px;
  width: 100%;
  padding: 0px 10px;
  /* Adjust based on the width of the icon */
  border-radius: 5px;
  font-size: 14px;
  color: #212121;

  &.error {
    border: 1px solid red;
  }

  &.valid {
    border: 1px solid #cad1d7;
  }
}

#myInput:active {
  border-color: #cad1d7;
}

#myInput:focus {
  border-color: #cad1d7;
}

#myInput::placeholder {
  color: #adb5bd;
  font-size: 14px;
}

.estimate-list-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}

.estimate-list-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.estimate-list-item:hover {
  background-color: #f5f5f5;
}

.estimate-not-found {
  padding: 10px;
  color: #777;
}

.base_background {
  padding: "20px" !important;
  background-color: "#fafafa" !important;
}

#inputGroupSelect01 {
  width: 5rem !important;
}

.inputFields-grid1 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}

.ant-picker-focused.ant-picker {
  box-shadow: none !important;
}

.customSingleDatePicker {
  border: 1px solid #eaeaea;
  min-height: 45px;
  border-radius: 5px;
  width: 100%;
}

.customSingleDatePicker .ant-picker .ant-picker-input:hover {
  background-color: transparent !important;
}

.without_borders {
  border: none !important;
  cursor: pointer;
  padding: 5px 10px;
}


.customSingleDatePicker:hover {
  border-color: #cad1d7;
  box-shadow: none;
}

.ant-picker-focused.ant-picker {
  border-color: #cad1d7 !important;
}

.customTestArea {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  font-size: 13px;
  background-color: transparent;
}

::placeholder {
  color: #8e8f99 !important;
  font-size: 13px !important;
}

.customLabel {
  font-size: 13px;
  color: #333333;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.customProfileLabel {
  font-size: 13px;
  color: #757575;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.customModalLabel {
  font-size: 13px;
  color: #757575;
  margin-bottom: 0.5rem;
}

.customSelectContainer {
  display: grid;
  grid-template-columns: 0fr 2fr;
}

.customSelect {
  background-color: white;

  &.ant-select-single {
    height: 45px;
  }

  .ant-select-selection-item {
    margin-left: 10%;
  }

  .ant-select-selector {
    border-right: 0px !important;
    border-color: 1px solid #e1e8f0 !important;
    box-shadow: none !important;
    border-radius: 5px 0px 0px 5px !important;
  }

  .ant-select-selector:hover {
    box-shadow: none !important;
  }

  .ant-select-selector:focus {
    box-shadow: none !important;
  }

  .ant-select-item-option-content {
    color: red !important;
    /* Change to your desired color */
  }

  .ant-select-item-option:hover {
    background-color: #f0f0f0 !important;
    /* Change to your desired hover background color */
    color: #1890ff !important;
    /* Change to your desired hover text color */
  }
}

.ant-select-selector {
  // height: 45px !important;
  border-radius: 5px !important;
  border: 1px solid #e1e8f0 !important;
}

.indivi_customSelect_input_field {
  background-color: white;

  &.ant-select-single {
    height: 45px;
    width: 126px;
  }

  .ant-select-selector {
    // border-right: 0px !important;
    border-color: #e1e8f0 !important;
    box-shadow: none !important;
    border-radius: 5px;
  }

  .ant-select-selection-search-input::placeholder {
    color: red !important;
  }

  .ant-select-selector:hover {
    box-shadow: none !important;
  }

  .ant-select-selector:focus {
    box-shadow: none !important;
  }

  .ant-select-item-option-content {
    color: red !important;
    /* Change to your desired color */
  }

  .ant-select-item-option:hover {
    background-color: #f0f0f0 !important;
    /* Change to your desired hover background color */
    color: #1890ff !important;
    /* Change to your desired hover text color */
  }
}

.indivi_customSelect {
  background-color: white;

  &.ant-select-single {
    height: 34px;
    width: 100%;
  }

  .ant-select-selection-item {
    // margin-left: 10%;
  }

  .ant-select-selector {
    // border-right: 0px !important;
    border-color: #e1e8f0 !important;
    box-shadow: none !important;
    border-radius: 5px;
  }

  .ant-select-selector:hover {
    box-shadow: none !important;
  }

  .ant-select-selector:focus {
    box-shadow: none !important;
  }

  .ant-select-item-option-content {
    color: red !important;
    /* Change to your desired color */
  }

  .ant-select-item-option:hover {
    background-color: #f0f0f0 !important;
    /* Change to your desired hover background color */
    color: #1890ff !important;
    /* Change to your desired hover text color */
  }
}

.addRequest_1 {
  display: grid;
  grid-template-columns: 50% 50%;
}

.overview_header {
  padding: 0 !important;
  border-bottom: 0 !important;
}

#no_cell_table {
  border-bottom: 0 !important;
}

.ant-progress-text {
  color: #72569c !important;
  /* Change the color to your desired color */
}

.searchBorderColor {
  border-right: 1px solid #cad1d7 !important;
}

.btn-secondary:hover {
  background-color: white;
}

.btn:hover {
  transform: none !important;
}

.status_select_input .btn-secondary {
  border-right: 1px solid #cad1d7 !important;
  background-color: white !important;
}

.maintainenceDetailHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.input-container {
  .ant-select-single {
    height: 45px !important;
  }

  .ant-select-selector {
    border: 1px solid #e6e6e6 !important;
    box-shadow: none !important;
    border-radius: 5px !important;
  }
}

.antdNavitem {
  padding: 10px !important;
}

.CustomSpinner {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 300px;
}

.SpinnerBody {}

.collapse_sideBar {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: rotate(180deg);
  transition: 0.5s;
}

.expand_sidebar {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: 0.5s;
  // transform: rotate(180deg);
}

.ant-select-selection-placeholder {
  color: #8e8f99 !important;
  font-size: 13px !important;
}

.sideView {
  display: flex;
  column-gap: 10px;
}

.sideViewContainer {
  border: 1px solid #72569c;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.copyLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emailModalFooter {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
}

.estimateClassName {
  border: none;
  resize: none;
  width: 100%;
  // margin-top: 5px;
}

.estimateClassName span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 13px !important;
  color: rgb(33, 33, 33) !important;
}

.estimateClassName ol {
  padding-left: 15px !important;
  margin-bottom: 0px !important;

  li {
    line-height: 24px;
  }
}

.estimateClassName::-webkit-scrollbar {
  display: none;
}

.scrollbar_design {
  white-space: "pre-wrap";
  max-height: "650px";
  overflow-y: "scroll";
  color: "black" !important;
  font-size: "14px" !important;
}

.scrollbar_design {

  p,
  ol,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
    color: #212121;
    line-height: 24px;
  }
}

.scrollbar_design::-webkit-scrollbar {
  display: none;
}

.leadSelectContainer {
  padding: 0px 20px;

  .selectLead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
  }
}

.selectLeadFooter {
  height: 300px;
}

.leadsList {
  overflow-y: scroll;
}

.leadsList::-webkit-scrollbar {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.profile_icon {
  height: 125px;
  border-radius: 50%;
  width: 125px;
  padding: 7px;
  border: 1.28px solid #e1e8f0;
  margin-top: 10px;
  background-color: white;
}

.profile_cam_icon {
  padding: 6px;
  border: 1px solid #e1e8f0;
  border-radius: 50%;
  width: 36px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.name_profile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.change {
  border: 1px solid #e1e8f0;
  border-radius: 5px;
}

.borderNone {
  border: none !important;
}

.pointer {
  cursor: pointer;
}

.center_the_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading_without_border {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #72569c;
}

.project_detail_bar {
  .ant-progress-inner {
    .ant-progress-bg {
      height: 100% !important;
      border-radius: 0px !important;
      background-color: #008000;
    }
  }
}

.payMethodHeader {
  background: #eef6f7;
}

.paymentMethod {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payDim {
  color: #c7c7c7;
}

.point {
  cursor: pointer;
}

.payment_type {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.inactiveRadio {
  border: 1px solid #c7c7c7 !important;
  color: #c7c7c7;
}

.contentEllipsis {
  // display: inline;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fromToDate {
  .ant-picker {
    border-radius: 0px;
    min-height: 46px;
  }

  .ant-picker-clear {
    display: none;
  }
}

.modal-dialog-payment-success {
  max-width: 500px !important;
  max-height: 600px !important;
}

.modal-dialog-payment-failure {
  max-width: 450px !important;
  max-height: 600px !important;
}

.spin-loading1 {
  @include flexCenter(center, center);
  height: 50vh;
}

.spin-loading1 .spinner-border {
  color: $primaryColor !important;
}

.paymentSuccess {
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  justify-content: center;
}

.paymentFailed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.paymentTitle {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1rem;
}

.amount {
  color: #51ca06;
}

.payment_box_head1 {
  background: #e1e8f0;
  padding: 16px;
  color: black;
  font-weight: 600;
  font-size: 15px;
  height: 48px;
  border-radius: 10px 10px 0px 0px;
}

.dropdown-item:hover {
  background-color: "#e6f4ff" !important;
  border-radius: 5px;
}

.image_container:hover .remove-img {
  opacity: 1;
  transition: 0.3s ease;
  z-index: 99;
}

.visible_hidden {
  visibility: hidden;
}

.documentClass {
  height: 115px;
  width: 95px;
  border: 1px solid #cecece;
  border-radius: 7px;
  padding: 5px;
}

.documentClassDiv {
  display: flex;
}

.sideWithDoc {
  border-radius: 5px;
  width: 117px;
  border: 1px dashed gray;
  padding: 13px;
  margin-left: 10px;
}

.image_container:hover .hideDelete {
  opacity: 1;
  transition: 0.3s ease;
  z-index: 99;
}

.hideDelete {
  opacity: 0;
  transition: 0.3s ease;
}

.description {
  margin-top: 24px;
  border: 1px solid rgb(225, 232, 240);
  padding: 0px 10px;
  border-radius: 5px;
  min-height: 125px;
}

.descTextBox {
  padding: 15px;
  border: 1px solid rgb(225, 232, 240);
  border-radius: 10px;
}

.ql-editor::-webkit-scrollbar {
  display: none;
}

.popUpDescription {
  .quill {
    padding: 24px 0px;
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .ql-container {
    min-height: 300px !important;
  }

  .ql-toolbar.ql-snow+.ql-container.ql-snow {
    // border-top: 1px solid !important;
  }

  .ql-editor::placeholder {
    font-style: normal !important;
  }

  .ql-toolbar .ql-formats {
    margin-right: 0px !important;
    border-top: none !important;
  }

  .ql-container.ql-snow {
    border: 1px solid rgb(225, 232, 240) !important;
    border-bottom: none !important;
    border-radius: 10px 10px 0px 0px;
  }

  .ql-toolbar.ql-snow {
    border-top: none !important;
    border: 1px solid rgb(225, 232, 240) !important;
    border-radius: 0px 0px 10px 10px;
  }



  .ql-editor {
    word-break: break-all !important;
    padding: 15px !important;
    border-radius: 15px 15px 0px 0px;

    span,
    p,
    ol,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 13px !important;
      font-family: "Poppins", sans-serif !important;
      color: #212121 !important;
      line-height: 24px;
    }
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
    font-size: 13px !important;
    font-family: "Poppins", sans-serif !important;
    top: 16px !important
  }
}

.termsPopDescription {
  .quill {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .ql-container {
    min-height: 150px !important;
  }

  .ql-toolbar.ql-snow+.ql-container.ql-snow {
    // border-top: 1px solid !important;
  }

  .ql-editor::placeholder {
    font-style: normal !important;
  }

  .ql-toolbar .ql-formats {
    margin-right: 0px !important;
    border-top: none !important;
  }

  .ql-container.ql-snow {
    border: none !important;
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-top: 0.5px solid #e1e8f0;
    margin-top: 24px;
  }

  .ql-editor {
    margin-bottom: 10px !important;
    word-break: break-all !important;
    // padding: 10px !important;
    padding: 0px !important;
    font-family: "Poppins", sans-serif !important;

    span,
    p,
    ol,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 13px !important;
      font-family: "Poppins", sans-serif !important;
      color: #212121 !important;
      line-height: 24px;
    }
  }

  .ql-editor.ql-blank::before {
    font-style: normal !important;
    font-size: 13px !important;
    font-family: "Poppins", sans-serif !important;
    top: 3px !important;
    left: 0px !important;
  }
}

.popUpDescription_preview {
  border: 1px solid #eef2f6;
  border-radius: 5px;
  padding: 15px;
  max-height: 650px;
  overflow-y: scroll;
  margin: 24px 0px;


  span,
  p,
  ol,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 13px !important;
    font-family: "Poppins", sans-serif !important;
    color: #212121 !important;
    line-height: 24px;
  }
}

strong {
  font-weight: bold;
}

.popupTerms_preview {
  padding: 1rem 1rem 1rem 1.5rem;
  white-space: initial;
  word-break: break-all;
  color: rgb(33, 33, 33);
  font-size: 13px;
  line-height: 23px;
  max-height: 650px;
  overflow-y: auto;

  span,
  p,
  ol,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 13px !important;
    font-family: "Poppins", sans-serif !important;
    color: #212121 !important;
    line-height: 24px;
  }

}

.senEmail {
  .quill {
    max-height: 300px !important;
    margin-bottom: 24px;
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .ql-container {
    min-height: 250px !important;
  }

  .ql-toolbar.ql-snow+.ql-container.ql-snow {
    // border-top: 1px solid !important;
  }

  .ql-editor::placeholder {
    font-style: normal !important;
  }

  .ql-toolbar .ql-formats {
    margin-right: 0px !important;
    border-top: none !important;
  }

  .ql-container.ql-snow {
    border: 1px solid rgb(225, 232, 240) !important;
    border-bottom: none !important;
    border-radius: 10px 10px 0px 0px;
  }

  .ql-toolbar.ql-snow {
    border-top: none !important;
    border: 1px solid rgb(225, 232, 240) !important;
    border-radius: 0px 0px 10px 10px;
  }

  //   .ql-toolbar.ql-snow::after {
  //     content: " asd";
  // }

  .ql-editor {
    word-break: break-all !important;
    padding: 15px !important;
    border-radius: 15px 15px 0px 0px;

    span,
    p,
    ol,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 13px !important;
      font-family: "Poppins", sans-serif !important;
      color: #212121 !important;
      line-height: 24px;
    }


  }

  .ql-editor.ql-blank::before {
    font-style: normal;
    font-size: 13px !important;
    font-family: "Poppins", sans-serif !important;
    top: 16px !important
  }
}

.additional-content {
  position: absolute;
  bottom: 0;
  font-size: 13px;
  color: #333333;
  cursor: pointer;
  bottom: 11%;
  left: 75%;
}

.vertical_align_top {
  vertical-align: top !important;
}

.inputTextPreTab {
  // width: 45%;
  // border: 1px solid #e4eaf1;
  // border-radius: 5px;
  border: none;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }

  input {
    border: none !important;
    width: 50%;
  }

  .ant-input:focus {
    box-shadow: none;
  }

  .ant-select-dropdown {
    width: 100px;
  }
}

.paymentDate {
  cursor: pointer;
  // border: 1px solid #e1e8f0;
  // border-radius: 10px;
  width: 65%;

  &:hover {
    border: 1px solid #e1e8f0;
    border-radius: 5px;
  }
}

.preTabInput {
  border: none;
  padding: 0px;
}

.estimateValue {
  display: flex;
  align-items: center;
  max-width: 50%;
  gap: 10%;

  &:hover {
    border: 1px solid #e1e8f0;
    border-radius: 5px;
  }
}

.outerDivDropDown {
  &:hover {
    border: 1px solid #e1e8f0;
    border-radius: 5px;
  }

  .ant-select-selector {
    border: none !important;
  }
}
.previewBottomTable {
  display: flex;
  justify-content: flex-end;
  table {
    width: 20%;
  }
}
.tableClass {
  width: 130px;
  color: #555555;
  background-color: white;
  text-align: right;
  padding: 15px 10px,
}

.estimateTableTable {
    .name , .desc{
        padding: 15px 10px;
        color: #555555;
        background-color: white;
        font-weight: 500;
        width: 20%;
    }
    .qty {
        font-weight: 500;
        padding: 15px 10px;
        width: 10%;
        color: #555555;
        background-color: white;
        text-align: center;
    }
    .labor , .material , .markup , .cost , .amount {
      padding: 15px 10px;
      font-weight: 500;
      width: 10%;
      color: #555555;
      background-color: white;
      text-align: end;
    }



}
