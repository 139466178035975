@media screen and (min-width: 1200px) and (max-width:1440px) {
  .pad-dash {
    padding: 1rem !important;
  }

  .dash_left {
    width: 74%;
  }

  .left_graph {
    width: 62%;
  }

  .right_graph {
    width: 36%;
  }

  .small_side_bar {
    .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
      padding: 1rem 0px 1rem 0 !important;
    }
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
    padding: 1rem 0px 1rem 1.5rem !important;
  }

  .pie_chart {
    height: 220px;
  }

  .display_pie_value h3 {
    font-size: 27px;
  }

  .listing_big_container {
    height: 65vh;
  }

  .table-responsive {
    overflow-x: auto !important;
  }

  .request-flex {
    margin-bottom: 30px;
  }

  .side_name {
    font-size: 14px;
  }
}