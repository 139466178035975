.profile_detail_card {
  padding: 30px;
  margin-bottom: 25px;

  .profile_page {
    width: 10%;
    margin-right: 20px;
    border-radius: 4px;

    img {
      border-radius: 4px;
    }

    .w_100 {
      display: flex;
      justify-content: center;
    }
  }

  .detail_with_icon {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .profile_map {
    width: 20%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .detail_icon {
    margin-right: 20px;
  }
}

.profile_image_data_map_div {
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  display: grid;
  grid-template-columns: 10% 90%;
}

.content_profile {
  width: 70%;
}

.individual_data_flex_div {
  display: grid;
  row-gap: 10px;
  .detail_with_icon {
    display: grid;
    grid-template-columns: 2% 98%;

    img,
    p {
      align-self: center;
    }
  }
}

.add_profile_image {
  background: white;
  border: 1px dashed #e4e4e4;
  border-radius: 0px;
  padding: 12px 13px;
  background: linear-gradient(
    92.37deg,
    #0a8080 0%,
    rgba(16, 222, 244, 0.74) 152.07%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: 100%;

  p {
    font-size: 13px;
  }
}

.upload_profile_image {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0%;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}

.profile_rating {
  width: 17% !important;
  border-right: 3px solid #f2f2f2;
}

.rating_works_parent {
  display: flex;
  align-items: center;

  p {
    margin: 0px;
  }
}

.active_profile_tab {
  border-bottom: 2px solid #72569c;
}

.tab_border {
  border-bottom: none;

  .nav-link:hover {
    border: 1px solid transparent;
  }

  .nav-item {
    margin-right: 50px;
  }

  .nav-link {
    padding: 12px 15px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #374957;
  }

  .inactive_profile_tab {
    border-bottom: none;
  }
}

.tab_content {
  padding-top: 40px;
}

.profile_border_bottom_div {
  border-bottom: 1px solid #dbdbdb;
}

.checkBox_service {
  position: relative !important;
  margin: 0px !important;
}

.cursor_default {
  cursor: default !important;
}

.image_holder {
  width: 142px;
  height: 147px;
  margin-right: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.add_works_image {
  border: 1px dashed #dbdbdb;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 18px 50px;

  p {
    margin: 0px;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
    color: #dbdbdb;
    font-weight: 600;
  }
}

.profile_experience {
  border-left: none !important;
  border-top: none !important;
  border-radius: 0px !important;
}

.working_picker {
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  bottom: 13px;
}

.remove_service {
  position: absolute;
  width: 15px !important;
  height: 15px !important;
  right: 4px;
  top: 4px;
  cursor: pointer;
}
